import React, { FC } from 'react'

import { HeroProps } from '../Hero'
import VideoBlock, { VideoBlockProps } from '../VideoBlock'
import VideoPresentation, { VideoPresentationProps } from '../VideoPresentation'
import { PictureInformationProps } from '../PictureInformation'

import * as SC from './styled'

export type PositiveFoodContainerProps = {
  heroPositiveProps?: HeroProps
  videoProps?: VideoBlockProps
  videoPresentationProps?: VideoPresentationProps
  children?: React.ReactNode
  pictureInformationProps?: PictureInformationProps[]
}

const PositiveFoodContainer: FC<PositiveFoodContainerProps> = ({
  heroPositiveProps,
  videoProps,
  videoPresentationProps,
  pictureInformationProps,
  children,
}) => {
  return (
    <SC.Container>
      <SC.Head>
        {heroPositiveProps && (
          <SC.HeroContent
            htmlTag="header"
            htmlTitleTag="h1"
            {...heroPositiveProps}
          />
        )}
        {(videoProps ||
          videoPresentationProps ||
          !!pictureInformationProps) && (
          <SC.Blocks>
            {videoProps && (
              <SC.VideoContainer>
                <VideoBlock {...videoProps} />
              </SC.VideoContainer>
            )}
            {videoPresentationProps && (
              <SC.VideoContainer>
                <VideoPresentation {...videoPresentationProps} />
              </SC.VideoContainer>
            )}
            {!!pictureInformationProps && (
              <>
                {pictureInformationProps.map((card, index) => (
                  <SC.StyledPictureInformation {...card} key={index} />
                ))}
              </>
            )}
          </SC.Blocks>
        )}
      </SC.Head>
      {children}
    </SC.Container>
  )
}

export default PositiveFoodContainer
