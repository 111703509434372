import React, { FC } from 'react'

import { PictureProps } from '../../atoms/Picture'
import { LinkProps } from '../../atoms/Link'
import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type BrandListProps = {
  title?: string
  text?: string
  brandsList?: {
    linkProps?: LinkProps
    pictureProps?: PictureProps
  }[]
  logoPictureProps?: {
    pictureProps?: PictureProps
  }
  actionButtonProps?: ActionButtonProps
}

const BrandList: FC<BrandListProps> = ({
  title,
  text,
  brandsList,
  logoPictureProps,
  actionButtonProps,
}) => {
  return (
    <SC.BrandList>
      <SC.TextsContainer>
        <SC.ContentLogo>
          <SC.Line />
          <SC.StyledLogoPicture {...logoPictureProps?.pictureProps} />
          <SC.Line />
        </SC.ContentLogo>

        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
      </SC.TextsContainer>

      <SC.PictureList>
        <SC.RowContainer>
          <SC.Row>
            {brandsList?.slice(0, 6)?.map((brand, i) => {
              return (
                <SC.StyledLink key={i} {...brand.linkProps}>
                  <SC.StyledPicture {...brand.pictureProps} />
                </SC.StyledLink>
              )
            })}
          </SC.Row>
          <SC.Row>
            {brandsList?.slice(6, 13)?.map((brand, i) => {
              return (
                <SC.StyledLink key={i} {...brand.linkProps}>
                  <SC.StyledPicture {...brand.pictureProps} />
                </SC.StyledLink>
              )
            })}
          </SC.Row>
          <SC.Row>
            {brandsList?.slice(13, 19)?.map((brand, i) => {
              return (
                <SC.StyledLink key={i} {...brand.linkProps}>
                  <SC.StyledPicture {...brand.pictureProps} />
                </SC.StyledLink>
              )
            })}
          </SC.Row>
        </SC.RowContainer>
      </SC.PictureList>

      {actionButtonProps && <SC.Button {...actionButtonProps} />}
    </SC.BrandList>
  )
}

export default BrandList
