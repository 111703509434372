import React, { FC } from 'react'

import BrandCard, { BrandCardProps } from '../BrandCard'

import * as SC from './styled'

export type BrandCardsBlockProps = MinimalSeoBlock & {
  title?: string
  cards?: Array<BrandCardProps>
}

const BrandCardsBlock: FC<BrandCardsBlockProps> = ({
  title,
  cards,
  ...className
}) => {
  return (
    <SC.Container {...className}>
      <SC.Title>{title}</SC.Title>
      <SC.CardsContent>
        {cards?.map((item, i) => {
          return <BrandCard key={i} {...item} />
        })}
      </SC.CardsContent>
    </SC.Container>
  )
}

export default BrandCardsBlock
