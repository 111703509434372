import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import tracking from 'src/tracking'

import {
  CulinaryFolder,
  CulinaryFolderFourItemsBlock as Data,
  Tag,
} from '../../../graphql/generated/api-graphql'
import CardsBlock, {
  CardsBlockProps,
} from '../../../components/molecules/CardsBlock'
import { transformTagForCloud } from '../../Common/tag'
import Router, { routes } from '../../../routes/Router'
import { transformFolderBigCard } from '../../FolderCard/transformFolderBigCard'
import { transformFolderCard } from '../../FolderCard/transformFolderCard'
import { Icons } from '../../../components/atoms/Icon'
import { BlockProps } from '../props'

export type CulinaryFolderFourItemsBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const CulinaryFolderFourItemsBlock: FC<CulinaryFolderFourItemsBlockProps> = ({
  data,
  template,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const props: CardsBlockProps = {
    title: data?.title ?? '',
    tags: data?.tags?.map((tag: Tag) =>
      transformTagForCloud(tag, `${template} - ${data.title}`)
    ),
    highlighted: transformFolderBigCard(
      t,
      data?.culinaryFolders?.[0],
      data?.title ?? undefined
    ),
    cards: data?.culinaryFolders
      ?.slice(1)
      ?.map((item: CulinaryFolder) =>
        transformFolderCard(t, item, data?.title ?? undefined)
      ),
    ...(router.pathname !== routes.folders.component && {
      actionButtonProps: {
        href: Router.getRouteUrl(routes.folders),
        onClick: () =>
          tracking.cta(t('see_more_folders'), data?.title ?? undefined),
        label: t('see_more_folders'),
        iconPosition: 'right',
        iconProps: {
          icon: Icons.arrowRight,
        },
      },
    }),
  }

  return <CardsBlock {...props} />
}

export default CulinaryFolderFourItemsBlock
