import React, { FC } from 'react'

import FAQEntry, { FAQEntryProps } from '../FAQEntry'

import * as SC from './styled'

export type FAQThemeProps = MinimalSeoBlock & {
  title?: string
  items?: FAQEntryProps[]
}

const FAQTheme: FC<FAQThemeProps> = ({
  htmlTag,
  htmlTitleTag,
  title,
  items,
}) => {
  return (
    <SC.FAQTheme as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.Entries>
        {items?.map((entry, i) => (
          <FAQEntry {...entry} key={i} />
        ))}
      </SC.Entries>
    </SC.FAQTheme>
  )
}

export default FAQTheme
