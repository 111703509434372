import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'

export const TableContainer = styled.div`
  overflow-x: auto;
`

export const Form = styled(FormikForm)``

export const Array = styled.table`
  border-radius: 1rem;
  margin-top: 4rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};

  width: 100%;
  min-width: max-content;
  min-height: 20rem;
`

export const HeadArray = styled.thead`
  margin-bottom: 2rem;

  & tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyChateau};
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    & th {
      padding: 1.5rem 0.5rem;
      text-align: left;
      ${({ theme }) => theme.textStyles.textSmallStrong};
      color: ${({ theme }) => theme.colors.arapawa};

      &:first-child {
        padding: 1.5rem 0.5rem 1.5rem 2rem;
      }
    }
  }
`

export const BodyArray = styled.tbody`
  & tr.break,
  & tr:first-child {
    margin-top: 2.2rem;
  }
  & tr:last-child {
    margin-bottom: 2rem;
  }
  & tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    & td:first-child a {
      color: ${({ theme }) => theme.colors.ruby};
    }

    & td {
      ${({ theme }) => theme.textStyles.textXSmall};
      color: ${({ theme }) => theme.colors.bismark};
      padding: 1rem 3rem;
    }
    & td:first-child {
      padding: 1rem 0 1rem 2rem;
    }
  }
`
