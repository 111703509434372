import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import DiscoverCard from '../DiscoverCard'

export const DiscoverBlock = styled.aside`
  padding: 3rem 1.5rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  &:not(:first-child) {
    margin-top: 3rem;
  }
  @media ${mq(sizes.tablet)} {
    align-self: center;
    width: 100%;
    max-width: min(
      calc(100vw - 3rem),
      ${({ theme }) => theme.sizes.scoping / 10}rem
    );
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    padding: 3rem 2rem 2rem;
    border-radius: 2rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2}
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  @media ${mq(sizes.tablet)} {
  }
`
export const Cards = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 2rem;
  &:not(:first-child) {
    margin-top: 3rem;
  }
  @media ${mq(sizes.tablet)} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: stretch;
  }
`
export const Card = styled(DiscoverCard)`
  width: 100%;
  max-width: 58rem;
`
