import tracking from 'src/tracking'

import { wediaImagesTransform } from '../../Common/wedia'
import { PushHpFullWidthBlock } from '../../../graphql/generated/api-graphql'
import { PictureEditoProps } from '../../../components/molecules/PictureEdito'
import { PictureEditoVariant } from '../../../components/molecules/PictureEdito/styled'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'

const PushHpFullWidthBlockTransform = (
  _t: any,
  data: PushHpFullWidthBlock
): PictureEditoProps => ({
  title: data?.title ?? '',
  intro: data?.subTitle ?? '',
  text: data?.body ?? '',
  variant: data?.colorVariant
    ? (data?.colorVariant as string) === PictureEditoVariant.Ruby
      ? PictureEditoVariant.Ruby
      : PictureEditoVariant.Yellow
    : undefined,
  imageProps: {
    maxWidth: 1280,
    width: 1280,
    height: 480,
    withHD: true,
    withLazyLoading: true,
    images: data?.image ? wediaImagesTransform(data.image) : undefined,
    alt: data?.title ?? data.image?.alt ?? '',
  },
  mobileImageProps: {
    maxWidth: 370,
    width: 370,
    height: 200,
    withHD: true,
    withLazyLoading: true,
    images: data?.image ? wediaImagesTransform(data.image) : undefined,
    alt: data?.title ?? data.image?.alt ?? '',
  },
  ...(data?.ctaBlock?.href && {
    actionButtonProps: {
      ...ctaBlockToActionButtonProps(data?.ctaBlock),
      onClick: () =>
        tracking.cards.highlight(
          data?.title ?? '',
          data?.ctaBlock?.label ?? ''
        ),
    },
  }),
})

export default PushHpFullWidthBlockTransform
