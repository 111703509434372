import styled from 'styled-components'

import TagsCloud from '../TagsCloud'
import SearchField from '../../atoms/SearchField'
import { mq, sizes } from '../../../theme'
import { Wrapper } from '../../form/fields/FormFieldInput/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0rem 1.5rem 1rem;
  gap: 2.3rem;
  @media ${mq(sizes.tablet)} {
    padding: 0rem 4rem 2rem;
    gap: 2.5rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH5}
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3}
  }
`
export const Field = styled(SearchField)`
  width: 100%;
  max-width: 80rem;
  & ${Wrapper} {
    width: 100%;
    padding: 1.6rem 1rem 1.6rem 2rem;
    border-radius: 1.2rem;
    border-color: ${({ theme }) => theme.colors.greyChateau};
  }
  & .react-autosuggest__suggestions-container--open {
    top: 100%;
    border-color: ${({ theme }) => theme.colors.greyChateau};
  }
`
export const Tags = styled(TagsCloud)`
  padding: 0;
`
