import { GoodPlansListProps } from '../../../components/molecules/GoodPlansList'
import { Plan } from '../../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../../Common/wedia'
import Router, { routes } from '../../../routes/Router'
import { PictureCardMessageProps } from '../../../components/molecules/PictureCardMessage'
import { imageMocks } from '../../../components/atoms/Picture/mocks'
import { actionButtonMock } from '../../../components/atoms/ActionButton/mocks'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import APP from '../../../configuration/app'
import tracking from '../../../tracking'

export const getCurrentPlans = (plans: Plan[]) => {
  const currentPlans = plans.filter((plan) => {
    return (
      plan.startAt <= new Date().toISOString() &&
      plan.endAt >= new Date().toISOString()
    )
  })
  return currentPlans
}

export const getNonAuthPlans = (plans: Plan[]) => {
  const nonAuthPlans = plans.filter((plan) => {
    return !plan.enableJwt
  })
  return nonAuthPlans
}

export const transformPlans = (
  plans: Plan[],
  t: any
): GoodPlansListProps['cards'] => {
  return plans.map((plan) => {
    return {
      title: plan.titleHeader || '',
      imageProps: {
        withHD: true,
        maxWidth: 250,
        width: 250,
        height: 250,
        alt: ' ',
        images: wediaImagesTransform(plan?.picture),
      },
      link: {
        href: Router.getRouteUrl(routes.plan, { slug: plan.slug }),
        label: t('plan_cta'),
        onClick: () => {
          tracking.game()
        },
      },
    }
  })
}

export const getNoGoodPlansPictureCardProps = (
  t: any
): PictureCardMessageProps => {
  return {
    imageProps: {
      ...imageMocks,
      images: [
        {
          // todo replace test image
          src: '/static/assets/images/tests/hero-banner.png',
          size: 400,
        },
      ],
    },
    intro: t('plan_noplan_intro'),
    title: t('plan_noplan_title'),
    text: t('plan_noplan_text'),
    actionButtonProps: {
      ...actionButtonMock,
      label: t('plan_noplan_button'),
      href: APP.REDUCTION_SLUG,
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
      variant: ActionButtonVariant.secondary,
    },
  }
}
