import React, { FC } from 'react'

import CheeseCard, { CheeseCardProps } from '../CheeseCard'

export type CheeseCardBlockProps = {
  cheeseCards?: CheeseCardProps[]
}

const CheeseCardBlock: FC<CheeseCardBlockProps> = ({ cheeseCards }) => {
  return (
    <>
      {cheeseCards &&
        cheeseCards.map((item, i) => {
          return <CheeseCard key={i} {...item} />
        })}
    </>
  )
}

export default CheeseCardBlock
