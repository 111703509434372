import React, { FC } from 'react'
import HtmlTitleH1, {
  HtmlTitleH1Props,
} from 'src/components/molecules/HtmlTitleH1'

import {
  PageTemplate,
  H1Block as Data,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'

export type H1BlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const H1Block: FC<H1BlockProps> = ({ data, template }) => {
  const props: HtmlTitleH1Props = {
    title: data?.title ?? '',
    isGeneric: template === PageTemplate.Generic && data?.order === 2,
  }

  return <HtmlTitleH1 {...props} />
}

export default H1Block
