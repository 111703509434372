import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'
import Icon from '../../atoms/Icon'
import ActionButton from '../../atoms/ActionButton'
import Html from '../../atoms/Html'

export const ProductCard = styled.article`
  min-width: 25rem;
  width: 25rem;
  height: 37rem;
`

export const CardFront = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: ${({ theme }) => theme.colors.killarney};
  padding: 1.5rem;
  border-radius: 1.5rem;
`

export const Container = styled.div<{ $active: boolean }>`
  transform-style: preserve-3d;
  transition: transform 1s ease;

  ${({ $active }) =>
    $active &&
    css`
      transform: rotateY(180deg);
    `}
`

export const CardBack = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border: 1px solid ${({ theme }) => theme.colors.gainsboro};
  border-radius: 1.5rem;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  height: 37rem;
  cursor: pointer;
`

export const BackgroundImage = styled(Picture)`
  display: block;
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  border-radius: 1.5rem 1.5rem 0 0;
  height: 25rem;
  width: 100%;

  & img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`

export const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 0 0 1.5rem 1.5rem;

  @media ${mq(sizes.tablet)} {
    width: 100%;
  }
`

export const Bezel = styled.svg`
  position: absolute;
  top: -0.9rem;
  width: 100%;
  height: 1rem;
  & path {
    fill: ${({ theme }) => theme.colors.pureWhite};
  }
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`

export const LogoContainer = styled(Link)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 13rem;
  height: 13rem;
  top: -4rem;
`

export const Content = styled.div`
  position: relative;
  min-height: 9rem;
`

export const Logo = styled(Picture)`
  width: 5rem;
  height: 5rem;

  & img {
    overflow: hidden;
    height: auto;
    object-fit: contain;
  }
`

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH4};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  padding: 0 1rem;
  margin: 1rem 0 0 0;
  word-break: break-word;
`

export const Close = styled.button`
  width: 100%;
  text-align: center;
  background: 0;
`

export const StyledIcon = styled(Icon)`
  height: 1.8rem;
  margin: 1.5rem auto 1.5rem auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
  cursor: pointer;
`

export const TitleBack = styled.p`
  ${({ theme }) => theme.textStyles.textSmallStrong};
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const TextBack = styled(Html)`
  ${({ theme }) => theme.textStyles.textSmall};
  color: ${({ theme }) => theme.colors.pureWhite};
  margin-top: 1.5rem;
`

export const StyledButton = styled(ActionButton)`
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
`
