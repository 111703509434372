import tracking, { TrackingCardPosition } from 'src/tracking'
import { CardContentType } from 'src/components/molecules/ContentCard'

import { Plate } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { HeroProps } from '../../components/molecules/Hero'
import { TagVariant } from '../../components/atoms/Tag/styled'

export const transformPlateBigCard = (
  t: any,
  data?: Plate,
  blockName?: string
): HeroProps => {
  return {
    title: data?.title,
    linkProps: {
      href: Router.getRouteUrl(routes.plate, {
        slug: data?.slug,
      }),
      onClick: () =>
        tracking.cards.block(
          blockName,
          CardContentType.Article,
          data?.title,
          TrackingCardPosition.HERO
        ),
    },
    isFullLink: true,
    buttonLabel: t('plateCard_buttonLabel'),
    ...(data?.brand && {
      brandLogoProps: data?.brand?.pictureLogo
        ? {
            alt: data?.brand?.name ?? data?.brand?.pictureLogo.alt ?? ' ',
            maxWidth: 80,
            width: 80,
            height: 80,
            withHD: true,
            withLazyLoading: true,
            images: wediaImagesTransform(data.brand.pictureLogo),
          }
        : undefined,
    }),
    imageProps: {
      maxWidth: 1280,
      width: 1200,
      height: 480,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.mainImage),
      alt: data?.title ?? data?.mainImage?.alt ?? '',
    },
    mobileImageProps: {
      maxWidth: 370,
      width: 370,
      height: 200,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.mainImage),
      alt: data?.title ?? data?.mainImage?.alt ?? '',
    },
    // topTagsNoMargin: true,
    topTags: [{ label: t('tag_plate'), variant: TagVariant.planche }],
    bottomTags: [
      ...(data?.tagsFree?.[0]
        ? [
            {
              label: data?.tagsFree?.[0]?.name,
              variant: TagVariant.diet,
            },
          ]
        : []),
      ...(data?.cheeses?.length ?? 0 > 0
        ? [
            {
              label: t('plate_cheese_count', { count: data?.cheeses?.length }),
              variant: TagVariant.primary,
            },
          ]
        : []),
      ...(data?.tags?.map((tag) => ({
        label: tag?.name,
        variant: TagVariant.diet,
      })) ?? []),
    ].slice(0, 2),
  }
}
