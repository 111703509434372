import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'

import { FaqContainerBlock as FaqContainerBlockData } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import FAQ, { FAQProps } from '../../../components/molecules/FAQ'
import TwoColumnsLayout from '../../../layouts/TwoColumnsLayout'
import { useRegisterBlock } from '../../RegisterBlock/useRegisterBlock'

export type FaqContainerBlockProps = Omit<BlockProps, 'data'> & {
  data: FaqContainerBlockData
}
const FaqContainerBlock: FC<FaqContainerBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  const [selectedTheme, setSelectedTheme] = useState('')

  const props: FAQProps = {
    title: data?.title ?? '',
    selectProps: {
      label: t('faq_select_label'),
      staticLabel: true,
      name: 'theme',
      options: [
        {
          label: t('faq_select_all_label'),
          value: '',
        },
      ].concat(
        data?.faqThemes?.map((theme, i) => ({
          label: theme.title?.substr(0, theme.title?.indexOf('(')) ?? '',
          value: i + '',
        })) ?? []
      ),
      value: selectedTheme,
      onChange: (e) => setSelectedTheme(e.target.value),
    },
    themes: data?.faqThemes
      ?.filter((_theme, i) => selectedTheme === '' || selectedTheme === i + '')
      ?.map((theme) => ({
        title: theme.title ?? '',
        items: theme.faqItems?.map((item, i) => ({
          index: i + 1 + '',
          title: item.question ?? '',
          text: item.answer ?? '',
          onClick: () =>
            tracking.faq(theme.title ?? undefined, item.question ?? undefined),
        })),
      })),
    contact: data?.contact ?? '',
  }

  return (
    <TwoColumnsLayout
      mainContent={<FAQ {...props} />}
      sideContent={useRegisterBlock()}
    />
  )
}

export default FaqContainerBlock
