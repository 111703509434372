import React, { FC } from 'react'
import { HtmlProps } from 'src/components/atoms/Html'

import * as SC from './styled'

export type ContactFormProps = {
  className?: string
  title?: string
  subtitle?: string
  children?: React.ReactNode
  textProps?: HtmlProps
}

const ContactForm: FC<ContactFormProps> = ({
  title,
  subtitle,
  children,
  className,
  textProps,
}) => {
  return (
    <SC.Container className={className}>
      <SC.Intro>
        <SC.Title>{title}</SC.Title>
        <SC.Subtitle>{subtitle}</SC.Subtitle>
      </SC.Intro>

      <div>{children}</div>
      <SC.StyledHtml {...textProps} />
    </SC.Container>
  )
}

export default ContactForm
