import styled from 'styled-components'

import { mq, sizes } from '../../../theme'

export const Container = styled.div`
  margin-bottom: 10rem;
  padding: 0 1.5rem;

  @media ${mq(sizes.tablet)} {
    padding: 0;
  }
`

export const Content = styled.div``

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3};
  color: ${({ theme }) => theme.colors.arapawa};
  margin: 5rem 0 3rem 0;
  text-align: center;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1};
  }
`

export const CardsContainer = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;

  @media ${mq(sizes.tablet)} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.4rem;
  }

  @media ${mq(sizes.desktop)} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const PaginationContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.pattensBlue};
  width: 100%;
  padding-top: 3rem;
  margin-top: 3rem;
`
