import React, { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import {
  Plate,
  PlatePaginator,
  PlatesListBlock as Data,
} from '../../../graphql/generated/api-graphql'
import CardsBlock, {
  CardsBlockProps,
} from '../../../components/molecules/CardsBlock'
import Router, { routes } from '../../../routes/Router'
import { transformPlateCard } from '../../PlateCard/transformPlateCard'
import { jsonLdPlates } from '../../PlateCard/plate'

export type PlatesListBlockProps = {
  data: Data
  plates?: PlatePaginator
}
const PlatesListBlock: FC<PlatesListBlockProps> = ({ data, plates }) => {
  const { query } = useRouter()
  const { t } = useTranslation()
  const parsedPageNumber = parseInt((query?.page as string) ?? '1', 10)
  const pageNumber: number = !isNaN(parsedPageNumber) ? parsedPageNumber : 1
  const ref = useRef<HTMLDivElement>(null)

  const props: CardsBlockProps = {
    title: data?.title ?? '',
    cards: plates?.data?.map((item: Plate) =>
      transformPlateCard(t, item, data?.title ?? undefined)
    ),
    ...(plates?.data && {
      jsonLd: jsonLdPlates(plates),
    }),
    paginationProps: {
      pageCount: plates?.paginatorInfo?.lastPage ?? 1,
      pageRangeDisplayed: 2,
      marginPagesDisplayed: 2,
      forcePage: (plates?.paginatorInfo?.currentPage ?? 1) - 1,

      hrefBuilder: (pageIndex) => {
        if (pageIndex === 1) {
          return Router.getRouteUrl(routes.plates)
        } else {
          return Router.getRouteUrl(routes.platesPage, { page: pageIndex })
        }
      },

      onPageChange: (e) => {
        if (e.selected + 1 !== pageNumber) {
          Router.push(
            Router.getRouteUrl(routes.platesPage, { page: e.selected + 1 }),
            undefined,
            {
              scroll: false,
            }
          )
          ref?.current?.scrollIntoView({
            behavior: 'smooth',
          })
        }
      },
    },
  }

  return <CardsBlock {...props} containerRef={ref} />
}

export default PlatesListBlock
