import { FC } from 'react'
import cx from 'classnames'
import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { ItemList } from 'schema-dts'

import { VideoProps } from '../../atoms/Video'
import { HtmlProps } from '../../atoms/Html'
import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type VideoPresentationProps = MinimalSeoBlock & {
  className?: string
  videos: VideoProps[]
  title?: string
  htmlProps?: HtmlProps
  actionButtonProps?: ActionButtonProps
}

const VideoPresentation: FC<VideoPresentationProps> = (props) => {
  const { className, htmlTag, videos, title, htmlProps, actionButtonProps } =
    props

  return (
    <SC.VideoPresentation className={cx('VideoBlock', className)} as={htmlTag}>
      <Head>
        <script
          {...jsonLdScriptProps<ItemList>({
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: videos?.map((item, index) => {
              const thumbnailUrl = item?.poster
                ? item?.poster?.match(/^http(s*):/)
                  ? item?.poster
                  : item?.jsonLdBasePath && item?.jsonLdBasePath + item?.poster
                : item?.sources?.[0].src
              return {
                '@type': 'VideoObject',
                position: index + 1,
                name: item.title ?? 'Video',
                contentUrl: item?.sources?.[0].src ?? '',
                thumbnailUrl,
                uploadDate:
                  item.jsonLdInfo?.uploadDate ?? new Date().toISOString(),
              }
            }),
          })}
        />
      </Head>
      <SC.Intro>
        {title && <SC.Title>{title}</SC.Title>}
        {htmlProps && <SC.Text {...htmlProps} />}
        {actionButtonProps && <SC.Button {...actionButtonProps} />}
      </SC.Intro>
      <SC.VideoContainer>
        {videos.map((video, i) => {
          return <SC.StyledVideo key={i} {...video} disableJsonLd={true} />
        })}
      </SC.VideoContainer>
    </SC.VideoPresentation>
  )
}

export default VideoPresentation
