import styled, { css } from 'styled-components'

import Html from '../../atoms/Html'
import { mq, sizes } from '../../../theme'

export const Container = styled.div<{ $isGeneric?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${({ theme, $isGeneric }) =>
    $isGeneric &&
    css`
      //background-color: ${theme.colors.pureWhite};
      padding: 4rem 2rem 2rem 2rem;
      margin-bottom: 4rem;
      border-radius: 0 0 2rem 2rem;
      margin-top: -6rem;

      & ${Title} {
        color: ${({ theme }) => theme.colors.ruby};
      }

      & ${StyledHtml} p {
        color: ${({ theme }) => theme.colors.matterhorn};
      }

      @media ${mq(sizes.tablet)} {
        padding: 4rem 5rem 5rem 5rem;
      }
    `}
`

export const StyledHtml = styled(Html)`
  & p {
    color: ${({ theme }) => theme.colors.grey};
  }
`
export const Title = styled.h2`
  margin: 0;
  ${({ theme }) => theme.textStyles.titleH2}
  color: ${({ theme }) => theme.colors.arapawa};
`
