import React, { FC, useState } from 'react'

import { Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type FAQEntryProps = MinimalSeoBlock & {
  index?: string
  title?: string
  text?: string
  onClick?: () => void
}

const FAQEntry: FC<FAQEntryProps> = ({
  htmlTag,
  htmlTitleTag,
  index,
  title,
  text,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <SC.FAQEntry as={htmlTag} $isOpen={isOpen}>
      <div onClick={!isOpen ? onClick : undefined}>
        <SC.Head onClick={() => setIsOpen((before) => !before)}>
          <SC.Index>{index}.</SC.Index>
          <SC.Title as={htmlTitleTag}>{title}</SC.Title>
          <SC.StyledIcon icon={isOpen ? Icons.minus : Icons.plus} />
        </SC.Head>
      </div>
      <SC.Text>{text}</SC.Text>
    </SC.FAQEntry>
  )
}

export default FAQEntry
