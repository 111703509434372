import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

import FormFieldCheckbox, {
  FormFieldCheckboxProps,
} from '../../form/fields/FormFieldCheckbox'
import { Icons } from '../../atoms/Icon'
import FormikField, { FormikFieldProps } from '../../form/FormikField'
import { usePrevious } from '../../../hooks/usePrevious'

import * as SC from './styled'

export type DropdownCheckboxPartProps = {
  dropdownHead?: any
  fields: {
    checkboxes?: FormikFieldProps
  }[]
  name?: string
  colorIcon?: boolean
  allFiltersProps?: FormFieldCheckboxProps
}

const DropdownCheckboxPart: FC<DropdownCheckboxPartProps> = ({
  dropdownHead,
  fields,
  name,
  colorIcon,
  allFiltersProps,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAllChecked, setAllChecked] = useState(false)
  const context = useFormikContext()
  const previousValues = usePrevious(context.getFieldProps(name ?? '').value)

  useEffect(() => {
    const currentValues = context.getFieldProps(name ?? '').value
    if (JSON.stringify(currentValues) === JSON.stringify(previousValues)) return
    if (currentValues.length === fields.length) {
      setAllChecked(true)
    } else {
      setAllChecked(false)
    }
  }, [context, fields.length, name, previousValues])

  const handleCheckAll = useCallback(() => {
    if (!name) return
    if (isAllChecked === true) {
      context.setFieldValue(name, [])
      setAllChecked(false)
    } else {
      const newValues = fields.map((field) => field.checkboxes?.value)
      context.setFieldValue(name, newValues)
      setAllChecked(true)
    }
  }, [isAllChecked, fields, name, context])

  return (
    <SC.Dropdown>
      {dropdownHead && (
        <SC.Title onClick={() => setIsOpen(!isOpen)}>
          {dropdownHead.icon && (
            <SC.StyledIcon
              icon={dropdownHead.icon}
              $color={colorIcon ? true : false}
              height={21}
              width={21}
            />
          )}
          {dropdownHead.title}
          <SC.StyledArrowIcon
            $isOpen={isOpen}
            icon={Icons.chevronDown}
            height={16}
            width={16}
          />
        </SC.Title>
      )}
      {fields && (
        <SC.Content $isOpen={isOpen}>
          {allFiltersProps && (
            <FormFieldCheckbox
              {...allFiltersProps}
              checked={isAllChecked}
              onClick={handleCheckAll}
              readOnly
            />
          )}
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              {field.checkboxes && (
                <FormikField {...field.checkboxes} name={name} />
              )}
            </React.Fragment>
          ))}
        </SC.Content>
      )}
    </SC.Dropdown>
  )
}

export default DropdownCheckboxPart
