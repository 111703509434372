import styled from 'styled-components'

import Picture from '../../atoms/Picture'
import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'

export const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 6rem 0 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${mq(sizes.tablet)} {
    padding: 16rem 0 12rem 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  width: 90%;
  padding: 4rem 1.5rem 3rem 1.5rem;
  border-radius: 1.5rem;

  @media ${mq(sizes.tablet)} {
    width: 67rem;
    border-radius: 4rem;
    padding: 4rem 2.5rem 3rem 2.5rem;
  }
`

export const Intro = styled.p`
  ${({ theme }) => theme.textStyles.titleH3};
  color: ${({ theme }) => theme.colors.pureWhite};
  background-color: ${({ theme }) => theme.colors.arapawa};
  padding: 1.5rem 2.5rem;
  border-radius: 1.5rem;
  position: absolute;
  top: -4rem;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH2};
    padding: 1.5rem 3.5rem;
  }
`

export const Title = styled.h5`
  ${({ theme }) => theme.textStyles.titleH6};
  color: ${({ theme }) => theme.colors.ruby};
  text-align: center;
  margin: 0 0 1.5rem 0;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH5};
  }
`

export const Text = styled.p`
  text-align: center;
  ${({ theme }) => theme.textStyles.textSmall};
  color: ${({ theme }) => theme.colors.arapawa};
  margin-bottom: 1.5rem;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.textMedium};
  }
`

export const BackgroundImage = styled(Picture)`
  display: block;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;

  & img {
    border-radius: 2rem;
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;

    @media ${mq(sizes.tablet)} {
      border-radius: 4rem;
    }
  }
`

export const Button = styled(ActionButton)`
  text-align: center;
  width: 100%;
  @media ${mq(sizes.tablet)} {
    width: auto;
  }
`
