import tracking, { TrackingCardPosition } from 'src/tracking'
import { CardContentType } from 'src/components/molecules/ContentCard'

import { CulinaryFolder, Maybe } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { HeroProps } from '../../components/molecules/Hero'

export const transformFolderBigCard = (
  t: any,
  data?: CulinaryFolder | Maybe<CulinaryFolder>,
  blockName?: string
  // noTagsNoMargin?: boolean
): HeroProps => ({
  title: data?.title,
  ...(data?.slug && {
    linkProps: {
      href: Router.getRouteUrl(routes.folder, { slug: data?.slug }),
      onClick: () =>
        tracking.cards.block(
          blockName,
          CardContentType.Folder,
          data.title,
          TrackingCardPosition.HERO
        ),
    },
    isFullLink: true,
    buttonLabel: t('folderCard_buttonLabel'),
  }),
  imageProps: {
    maxWidth: 1280,
    width: 1200,
    height: 480,
    withHD: true,
    withLazyLoading: false,
    images: wediaImagesTransform(data?.mainImage),
    alt: data?.title ?? data?.mainImage?.alt ?? '',
  },
  mobileImageProps: {
    maxWidth: 370,
    width: 370,
    height: 200,
    withHD: true,
    withLazyLoading: false,
    images: data?.mainImage ? wediaImagesTransform(data.mainImage) : undefined,
    alt: data?.title ?? data?.mainImage?.alt ?? '',
  },
  // topTagsNoMargin: noTagsNoMargin ?? true,
  topTags: [{ label: t('tag_folder'), variant: TagVariant.folder }],
  bottomTags: data
    ? [
        // drop all the tags in order then slice the first 2
        ...(data?.positiveFood
          ? [
              {
                label: t('positive_food'),
                variant: TagVariant.positive,
              },
            ]
          : []),
        ...(data?.articles
          ? [
              {
                label:
                  data?.articles.length +
                  ' ' +
                  (data?.articles.length > 1
                    ? t('tag_articles')
                    : t('tag_article')),
                variant: TagVariant.article,
              },
            ]
          : []),
        ...('tagsConsumptionTime' in data && data?.tagsConsumptionTime?.[0]
          ? [
              {
                label: data?.tagsConsumptionTime?.[0]?.name,
                variant: TagVariant.meal,
              },
            ]
          : []),
        ...('tagsTheme' in data && data?.tagsTheme?.[0]
          ? [
              {
                label: data?.tagsTheme?.[0]?.name,
                variant: TagVariant.meal,
              },
            ]
          : []),
      ].slice(0, 2)
    : undefined,
})
