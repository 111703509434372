import tracking, { TrackingCardPosition } from 'src/tracking'

import { Plate } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { CardContentType } from '../../components/molecules/ContentCard'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { FolderCardProps } from '../../components/molecules/FolderCard'

export const transformPlateCard = (
  t: any,
  data?: Plate,
  blockName?: string
): FolderCardProps => {
  return {
    type: CardContentType.Article,
    title: data?.title,
    linkProps: {
      href: Router.getRouteUrl(routes.plate, {
        slug: data?.slug,
      }),
      onClick: () =>
        tracking.cards.block(
          blockName,
          CardContentType.Article,
          data?.title,
          TrackingCardPosition.CONTENT
        ),
    },
    ...(data?.brand && {
      brandLogoProps: data?.brand?.pictureLogo
        ? {
            alt: data?.brand?.name ?? data?.brand?.pictureLogo.alt ?? ' ',
            maxWidth: 80,
            width: 80,
            height: 80,
            withHD: true,
            withLazyLoading: true,
            images: wediaImagesTransform(data.brand.pictureLogo),
          }
        : undefined,
    }),
    imageProps: {
      maxWidth: 450,
      width: 384,
      height: 240,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
      placeholder: !data?.thumbnail01 && !data?.mainImage,
      alt: data?.title ?? data?.thumbnail01?.alt ?? data?.mainImage?.alt ?? '',
    },
    tags: [
      // drop all the tags in order then slice the first 2
      { label: t('tag_plate'), variant: TagVariant.article },
      ...(data && 'tagsFree' in data && data?.tagsFree?.[0]
        ? [
            {
              label: data?.tagsFree?.[0]?.name,
              variant: TagVariant.diet,
            },
          ]
        : []),
      ...(data?.cheeses?.length ?? 0 > 0
        ? [
            {
              label: t('plate_cheese_count', { count: data?.cheeses?.length }),
              variant: TagVariant.primary,
            },
          ]
        : []),
      ...(data?.tags?.map((tag) => ({
        label: tag?.name,
        variant: TagVariant.diet,
      })) ?? []),
    ].slice(0, 2),
  }
}
