import React, { FC } from 'react'

import { PictureProps } from '../../atoms/Picture'
import { LinkProps } from '../../atoms/Link'
import { Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type BrandCardProps = MinimalSeoBlock & {
  className?: string
  brandLogoProps?: PictureProps
  brandLogoLinkProps?: LinkProps
  children?: React.ReactNode | React.ReactElement | React.ReactNodeArray
  text?: string
  linkProps?: LinkProps
  buttonLabel?: string
  isFullLink?: boolean
}

const BrandCard: FC<BrandCardProps> = ({
  className,
  htmlTag,
  brandLogoProps,
  brandLogoLinkProps,
  children,
  text,
  linkProps,
  buttonLabel,
  isFullLink,
}) => {
  return (
    <SC.BrandCard className={className} as={htmlTag}>
      <SC.MainLink {...(isFullLink && linkProps)}>
        <SC.CardLink {...(!isFullLink && linkProps)}>
          {brandLogoProps && (
            <>
              <SC.LogoContainer {...brandLogoLinkProps}>
                <SC.Logo {...brandLogoProps} />
              </SC.LogoContainer>
              <SC.LogoSpacer />
            </>
          )}
          <SC.Content>
            {text && <SC.Text>{text}</SC.Text>}
            {linkProps && buttonLabel && (
              <SC.ButtonContainer>
                <SC.Button>{buttonLabel}</SC.Button>
                <SC.Arrow icon={Icons.arrowRight} />
              </SC.ButtonContainer>
            )}
          </SC.Content>
        </SC.CardLink>
      </SC.MainLink>
      {children}
    </SC.BrandCard>
  )
}

export default BrandCard
