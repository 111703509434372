import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'

import {
  PageTemplate,
  PositiveFoodDynamicFormatsBlock as Data,
  Format,
} from '../../../../../graphql/generated/api-graphql'
import ProductCardBlock, {
  ProductCardBlockProps,
} from '../../../../../components/molecules/ProductCardBlock'
import { wediaImagesTransform } from '../../../../Common/wedia'
import { ActionButtonVariant } from '../../../../../components/atoms/ActionButton/styled'
import Router from '../../../../../routes/Router'
import routes from '../../../../../routes/definitions'
import { BlockProps } from '../../../props'
import { ProductPopinProps } from '../../../../../components/molecules/ProductPopin'
import productPopinTransform from '../../../../ProductPopin/transform'

export type PositiveFoodDynamicFormatsBlockProps = BlockProps & {
  data: Data
}
const PositiveFoodDynamicFormatsBlock: FC<
  PositiveFoodDynamicFormatsBlockProps
> = ({ data, pagesByTemplate }) => {
  const { t } = useTranslation()

  const [popinData, setPopinData] = useState<ProductPopinProps | undefined>(
    undefined
  )
  const [openOpin, setOpenPopin] = useState(false)

  const handleClose = useCallback(() => {
    setOpenPopin(false)
  }, [])

  const handleLoadOpenPopin = useCallback(
    (product: Format) => {
      const transformed = productPopinTransform({
        t,
        data: { format: product },
      })
      setPopinData(transformed)
      setOpenPopin(true)
    },
    [t]
  )

  const productPopinProps: ProductPopinProps | undefined = useMemo(() => {
    return popinData
      ? {
          ...popinData,
          popin: {
            ...popinData.popin,
            isOpen: openOpin,
            closeHandler: handleClose,
          },
        }
      : undefined
  }, [handleClose, openOpin, popinData])

  const positiveFoodDynamicFormatsBlockProps:
    | ProductCardBlockProps
    | undefined = data
    ? {
        title: data.title ?? undefined,
        tags: data.tags
          ? data.tags.map((tag) => ({
              label: tag.name,
              value: tag.slug,
              href: Router.getRouteUrl(routes.tag, { slug: tag.slug }),
              onClick: () => tracking.tag(tag.name, data.title ?? ''),
            }))
          : undefined,
        ...(pagesByTemplate?.[PageTemplate.HpBrands]?.slug && {
          actionButtonProps: {
            href: pagesByTemplate[PageTemplate.HpBrands].slug,
            text: t('see_more_products'),
            variant: ActionButtonVariant.secondary,
            onClick: () =>
              tracking.cta(t('see_more_products'), data.title ?? undefined),
          },
        }),
        productPopinProps: productPopinProps,
        cards: data.formats
          ? data.formats.map((card) => ({
              imageProps: {
                alt: card.packshot?.alt ?? '',
                maxWidth: 256,
                width: 256,
                withHD: true,
                images: wediaImagesTransform(card.packshot),
              },
              title: card.title,
              titleBack: card.flipCardTitle ?? undefined,

              textBack: card.flipCardBody
                ? {
                    text: card.flipCardBody,
                  }
                : undefined,
              actionButtonProps: card
                ? {
                    onClick: () => handleLoadOpenPopin(card),
                    label: t('positive_food_view_product'),
                    variant: ActionButtonVariant.yellow,
                  }
                : undefined,
              brandLogoProps: card.mainBrand?.pictureLogo
                ? {
                    alt: card.mainBrand.pictureLogo?.alt ?? '',
                    maxWidth: 50,
                    width: 50,
                    withHD: true,
                    images: wediaImagesTransform(card.mainBrand.pictureLogo),
                  }
                : undefined,
            }))
          : undefined,
      }
    : undefined

  return positiveFoodDynamicFormatsBlockProps ? (
    <ProductCardBlock {...positiveFoodDynamicFormatsBlockProps} />
  ) : null
}

export default PositiveFoodDynamicFormatsBlock
