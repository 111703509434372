import tracking from 'src/tracking'

import { wediaImagesTransform } from '../../Common/wedia'
import { PushHpCardsBlock } from '../../../graphql/generated/api-graphql'
import { DiscoverBlockProps } from '../../../components/molecules/DiscoverBlock'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'
import { DiscoverCardProps } from '../../../components/molecules/DiscoverCard'

const discoverBlockTransform = (
  _t: any,
  data: PushHpCardsBlock
): DiscoverBlockProps => ({
  title: data?.title ?? '',
  items:
    data?.hpCards?.map(
      (card) =>
        ({
          title: card?.title ?? '',
          ...(card?.image && {
            imageProps: {
              alt: card?.title ?? card.image.alt ?? '',
              maxWidth: 370,
              width: 370,
              height: 171,
              withHD: true,
              withLazyLoading: true,
              images: card?.image
                ? wediaImagesTransform(card.image)
                : undefined,
            },
          }),
          ...(card?.ctaBlock && {
            linkProps: {
              ...ctaBlockToActionButtonProps(card.ctaBlock),
              onClick: () =>
                tracking.cta(
                  card.ctaBlock?.label ?? undefined,
                  card?.title ?? data?.title ?? undefined
                ),
            },
          }),
          backgroundColor: card.backgroundColor,
        } as DiscoverCardProps)
    ) ?? [],
})

export default discoverBlockTransform
