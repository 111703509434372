import tracking from 'src/tracking'

import { wediaImagesTransform } from '../../Common/wedia'
import {
  ImagePosition,
  PushHpThumbnailBlock,
} from '../../../graphql/generated/api-graphql'
import { BlogCardsBlockProps } from '../../../components/molecules/BlogCardsBlock'
import { BlogCardImagePosition } from '../../../components/molecules/BlogCard/styled'
import Router, { routes } from '../../../routes/Router'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'

const PushHpBlockTransform = (
  _t: any,
  data: PushHpThumbnailBlock
): BlogCardsBlockProps => ({
  //title: data?.title ?? '',
  ...((data?.tags?.length ?? 0) > 0 && {
    tagsCloud: {
      tags: data?.tags?.map((tag) => ({
        text: tag?.name,
        href: Router.getRouteUrl(routes.tag, { slug: tag?.slug }),
        onClick: () => tracking.tag(tag?.name ?? '', data?.title ?? ''),
      })),
    },
  }),
  cards: [
    {
      title: data?.cardTitle ?? '',
      subtitle: data?.cardSubTitle ?? '',
      htmlProps: {
        text: data?.body ?? '',
      },
      imagePosition:
        data?.imagePosition === ImagePosition.PictureLeft
          ? BlogCardImagePosition.pictureLeft
          : BlogCardImagePosition.pictureRight,
      imageProps: {
        maxWidth: 1280,
        width: 1280,
        height: 512,
        withHD: true,
        withLazyLoading: false,
        images: data?.image ? wediaImagesTransform(data.image) : undefined,
        alt: data?.cardTitle ?? data.image?.alt ?? '',
      },
      mobileImageProps: {
        maxWidth: 370,
        width: 370,
        height: 200,
        withHD: true,
        withLazyLoading: false,
        images: data?.image ? wediaImagesTransform(data.image) : undefined,
        alt: data?.title ?? data.image?.alt ?? '',
      },
      ...(data?.ctaBlock?.href && {
        actionButtonProps: {
          ...ctaBlockToActionButtonProps(data?.ctaBlock),
          variant: ActionButtonVariant.secondary,
          iconPosition: 'right',
          iconProps: {
            icon: Icons.arrowRight,
          },
        },
      }),
    },
  ],
})

export default PushHpBlockTransform
