import tracking from 'src/tracking'

import { wediaImagesTransform } from '../../Common/wedia'
import {
  DefaultLink,
  HeroBannerSliderBlock,
} from '../../../graphql/generated/api-graphql'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'
import { HeroSliderProps } from '../../../components/molecules/HeroSlider'

const heroBannerSliderBlockTransform = (
  _t: any,
  data: HeroBannerSliderBlock,
  preload?: boolean
): HeroSliderProps => ({
  slides:
    data?.slides?.map((slide, index) => ({
      title: slide?.title ?? '',
      subtitle: slide?.subTitle ?? '',
      text: slide?.body ?? '',
      imageProps: {
        maxWidth: 1280,
        width: 1280,
        height: 512,
        withHD: true,
        withLazyLoading: false,
        images: slide?.image ? wediaImagesTransform(slide.image) : undefined,
        placeholder: true,
        alt: slide?.title ?? slide?.image?.alt ?? '',
        preload: preload && index === 0,
      },
      imageMobileProps: {
        maxWidth: 600,
        width: 320,
        height: 200,
        withHD: true,
        withLazyLoading: false,
        images: slide?.imageMobile
          ? wediaImagesTransform(slide.imageMobile)
          : slide?.image
          ? wediaImagesTransform(slide.image)
          : undefined,
        placeholder: true,
        preload: preload && index === 0,
      },
      linkProps: {
        ...ctaBlockToActionButtonProps(slide?.ctaBlock as DefaultLink),
        onClick: () => tracking.cards.hero(slide?.title ?? ''),
      },
      buttonLabel: slide?.ctaBlock?.label ?? slide?.subTitle ?? '',
    })) ?? [],
})

export default heroBannerSliderBlockTransform
