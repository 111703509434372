import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Hero from '../Hero'
import PictureInformation from '../PictureInformation'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

export const HeroContent = styled(Hero)``

export const Head = styled.div``

export const VideoContainer = styled.div`
  margin-block: 2rem; // adding to the gap and padding
`
export const StyledPictureInformation = styled(PictureInformation)``

export const HeroFooter = styled(Hero)`
  margin-bottom: 2rem;

  @media ${mq(sizes.tablet)} {
    margin-bottom: 4rem;
  }
`

export const Blocks = styled.div`
  display: flex;
  flex-flow: column;
  gap: 2rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  border-radius: 0 0 3rem 3rem;
  margin: 0 1.5rem;
  @media ${mq(sizes.tablet)} {
    margin: 0;
    gap: 4rem;
    padding: 4rem 3rem;
    border-radius: 0 0 4rem 4rem;
  }
`
