import styled from 'styled-components'

export const FAQTheme = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gainsboro};
  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gainsboro};
  }
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Title = styled.span`
  ${({ theme }) => theme.textStyles.titleH4};
  color: ${({ theme }) => theme.colors.ruby};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
`
export const Entries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`
