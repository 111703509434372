import React, { FC } from 'react'

import ActionButton, { ActionButtonProps } from '../../atoms/ActionButton'
import { PictureProps } from '../../atoms/Picture'

import * as SC from './styled'

export type PictureInformationProps = MinimalSeoBlock & {
  className?: string
  imageProps?: PictureProps
  title?: string
  text?: string
  actionButtonProps?: ActionButtonProps
}

const PictureInformation: FC<PictureInformationProps> = (props) => {
  const { className, imageProps, title, text, actionButtonProps } = props

  return (
    <SC.Container className={className}>
      <SC.Content>
        <SC.TextContent>
          {title && <SC.Title>{title}</SC.Title>}
          {text && <SC.Text>{text}</SC.Text>}
          {actionButtonProps && <ActionButton {...actionButtonProps} />}
        </SC.TextContent>
        <SC.BackgroundImage {...imageProps} />
      </SC.Content>
    </SC.Container>
  )
}

export default PictureInformation
