import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import TagsCloud from '../TagsCloud'
import ActionButton from '../../atoms/ActionButton'
import ProductCard from '../ProductCard'

export const CardsBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;

  @media ${mq(sizes.tablet)} {
    padding: 5rem 2rem;
  }
  @media ${mq(sizes.desktop)} {
    padding: 5rem 4rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3};
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  flex-grow: 1;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1};
  }
`

export const Tags = styled(TagsCloud)`
  padding: 1.5rem 0 0;
`

export const GridCSS = css`
  display: grid;
  justify-items: center;
  justify-content: center;
`

export const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  gap: 2.5rem;
  overflow: auto visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  padding: 0 1.5rem;

  @media ${mq(sizes.tablet)} {
    padding: 0;
    ${GridCSS}
    gap: 3rem;
    grid-template-columns: repeat(2, 25rem);
  }

  @media ${mq(sizes.desktop)} {
    ${GridCSS}
    grid-template-columns: repeat(3, 25rem);
  }

  @media ${mq(sizes.scoping)} {
    ${GridCSS}
    grid-template-columns: repeat(4, 25rem);
  }
`

export const StyledProductCard = styled(ProductCard)``

export const Button = styled(ActionButton)`
  margin-top: 4rem;
`
