import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { HeroBannerBlock as HeroBannerBlockData } from '../../../graphql/generated/api-graphql'
import Hero, { HeroProps } from '../../../components/molecules/Hero'
import { BlockProps } from '../props'

import heroBannerBlockTransform from './transform'

export type HeroBannerBlockProps = Omit<BlockProps, 'data'> & {
  data: HeroBannerBlockData
}
const HeroBannerBlock: FC<HeroBannerBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  const heroProps: HeroProps = heroBannerBlockTransform(t, data)

  return <Hero {...heroProps} />
}

export default HeroBannerBlock
