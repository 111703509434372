import React from 'react'

import { HtmlProps } from '../../atoms/Html'

import * as SC from './styled'

export type HtmlTextBlockProps = {
  className?: string
  title?: string
  htmlProps?: HtmlProps
  isGeneric?: boolean
}

const HtmlTextBlock = React.forwardRef<HTMLDivElement, HtmlTextBlockProps>(
  (props, ref) => {
    const { className, title, htmlProps, isGeneric } = props
    return (
      <SC.Container className={className} $isGeneric={isGeneric} ref={ref}>
        {title && <SC.Title>{title}</SC.Title>}
        {htmlProps && <SC.StyledHtml {...htmlProps} />}
      </SC.Container>
    )
  }
)

HtmlTextBlock.displayName = 'HtmlTextBlock'

export default HtmlTextBlock
