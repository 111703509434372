import { wediaImagesTransform } from '../../Common/wedia'
import { HeroBannerCheesesBlock } from '../../../graphql/generated/api-graphql'
import { HeroBannerProps } from '../../../components/molecules/HeroBanner'

const heroBannerBlockTransform = (
  _t: any,
  data: HeroBannerCheesesBlock
): HeroBannerProps => ({
  bannerProps: {
    text: data?.title ?? '',
    imageProps: {
      maxWidth: 1280,
      withHD: true,
      withLazyLoading: false,
      images: data?.image ? wediaImagesTransform(data.image) : undefined,
      alt: data?.title ?? data.image?.alt ?? '',
    },
    mobileImageProps: {
      maxWidth: 800,
      withHD: true,
      withLazyLoading: false,
      images: data?.image ? wediaImagesTransform(data.image) : undefined,
      alt: data?.title ?? data.image?.alt ?? '',
    },
  },
  title: data?.subTitle ?? '',
  text: data?.introduction ?? '',
})

export default heroBannerBlockTransform
