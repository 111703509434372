import styled from 'styled-components'

import VideoContent from '../../atoms/Video'
import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'
import ActionButton from '../../atoms/ActionButton'

export const VideoPresentation = styled.section``

export const Intro = styled.div`
  padding: 3rem 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};

  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH3}
  }
`
export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textSmall}
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};

  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.textMedium}
  }
`

export const Button = styled(ActionButton)`
  align-self: center;
`

export const VideoContainer = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;

  @media ${mq(sizes.desktop)} {
    flex-direction: row;
    padding: 2rem;
    gap: 3rem;
  }
`

export const StyledVideo = styled(VideoContent)`
  width: 100%;

  @media ${mq(sizes.desktop)} {
    width: 50%;
  }
`
