import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'
import Icon from '../../atoms/Icon'

export enum HeroVariant {
  normal = 'normal',
  positive = 'positive',
}

export const BrandCard = styled.article`
  width: 100%;
  @media ${mq(sizes.tablet)} {
    padding-top: 5.8rem;
    width: auto;
  }
`

const mainStyles = css`
  border-radius: 2rem;
  overflow: hidden;
  position: relative;

  @media ${mq(sizes.tablet)} {
    border-radius: 3rem;
    min-height: 30rem;
  }
`
export const Main = styled.div`
  ${mainStyles}
`
export const MainLink = styled(Link)`
  ${mainStyles}
`

const cardStyles = css`
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  align-items: center;
  border-radius: 2rem;
  max-width: 100%;

  @media ${mq(sizes.tablet)} {
    flex-direction: column;
  }

  @media ${mq(sizes.desktop)} {
    width: 26rem;
  }
`

export const Card = styled.div`
  ${cardStyles}
`
export const CardLink = styled(Link)`
  ${cardStyles}
`

export const LogoContainer = styled(Link)<{
  $isGeneric?: boolean
  $variant?: HeroVariant
}>`
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media ${mq(sizes.tablet)} {
    position: absolute;
    width: 20rem;
    height: 20rem;
    padding-top: 2.4rem;
    top: -4.5rem;
    background-color: ${({ theme }) => theme.colors.pureWhite};
    border-radius: 100%;
  }
`

export const LogoSpacer = styled.div<{ $isGeneric?: boolean }>`
  @media ${mq(sizes.tablet)} {
    width: 100%;
    height: 7.5rem;
    ${({ $isGeneric }) =>
      $isGeneric &&
      css`
        display: none;
      `}
  }
`

export const Content = styled.div`
  min-height: 13rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${mq(sizes.tablet)} {
    min-height: 17rem;
    align-items: center;
    justify-content: space-between;
  }
`

export const Logo = styled(Picture)`
  width: 9.6rem;
  height: 9.6rem;

  & img {
    height: auto;
    object-fit: contain;
    margin: 0 auto;
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.bismark};
  padding: 1rem;
  flex-grow: 1;
  text-align: left;
  word-break: break-word;
  @media ${mq(sizes.tablet)} {
    text-align: center;
    padding: 1rem 2rem 0;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  @media ${mq(sizes.tablet)} {
    padding: 0;
  }
`

export const Button = styled.div`
  ${({ theme }) => theme.textStyles.titleH6};
  color: ${({ theme }) => theme.colors.arapawa};
  padding: 1rem;
  @media ${mq(sizes.tablet)} {
    padding: 2.5rem 0;
    text-align: center;
  }
`

export const Arrow = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.greyChateau};
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
