import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PageTemplate,
  TitleBodyBlock as Data,
} from '../../../graphql/generated/api-graphql'
import HtmlTextBlock, {
  HtmlTextBlockProps,
} from '../../../components/molecules/HtmlTextBlock'
import { BlockProps } from '../props'
import { fixOneTrustTag } from '../../../helpers/StringHelpers'

export type TitleBodyBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const TitleBodyBlock: FC<TitleBodyBlockProps> = ({ data, template }) => {
  const props: HtmlTextBlockProps = {
    title: data?.title ?? '',
    htmlProps: {
      text: fixOneTrustTag(data?.body ?? ''),
    },
    isGeneric: template === PageTemplate.Generic && data?.order === 2,
  }

  const { t } = useTranslation()

  const ref = useRef<HTMLDivElement>(null)

  // adds Axeptio content to the chosen element
  useEffect(() => {
    if (!ref.current) return
    window._axcb = window._axcb || []
    window._axcb.push(function (sdk: any) {
      const renderAxeptioVendorsToPage = (element: any) => {
        const targetElement =
          typeof element === 'string'
            ? document.querySelector(element)?.parentElement
            : element?.parentElement

        const html = sdk.getCookiesConfig().steps.map((step: any) => {
          if (step.layout === 'category' || step.layout === 'info') {
            const vendors = step.vendors.map((vendor: any) => {
              return `
<li>
  <strong><a href="${vendor.policyUrl}" target="_blank">${
                vendor.title
              }</a></strong>
  ${vendor.shortDescription ? `<br />${vendor.shortDescription}` : ''}
  ${vendor.longDescription ? `<br />${vendor.longDescription}` : ''}
</li>
                `
            })
            return `
<a name="axeptio-cookies"></a>
<h3>${t('cookies_title')}</h3>
${step.vendors && step.vendors.length > 0 ? `<ul>${vendors.join('')}</ul>` : ''}
              `
          }
        })
        if (targetElement instanceof Element) {
          targetElement.outerHTML = html.join('')
        }
      }

      renderAxeptioVendorsToPage('.js-axeptio-recap')
    })
  }, [t])

  return <HtmlTextBlock {...props} ref={ref} />
}

export default TitleBodyBlock
