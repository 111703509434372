import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import tracking from 'src/tracking'
import { useUpdateEffect } from 'usehooks-ts'
import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { ItemList } from 'schema-dts'

import {
  ArticleUnionPaginator,
  ListArticlesBlock as BlockData,
} from '../../../graphql/generated/api-graphql'
import AllCardsListing, {
  AllCardsListingProps,
} from '../../../components/molecules/AllCardsListing'
import Router, { routes } from '../../../routes/Router'
import { AllFiltersFormProps } from '../../../components/forms/AllCheesesFiltersForm'
import { formikFieldMocks } from '../../../components/form/FormikField/mocks'
import FormFieldSelect from '../../../components/form/fields/FormFieldSelect'
import { PaginationProps } from '../../../components/molecules/Pagination'
import { getFilterOptions, getFiltersFromUrl } from '../../Common/filters'
import { transformArticleCard } from '../../ArticleCard/transformArticleCard'
import ArticleCard, {
  ArticleCardProps,
} from '../../../components/molecules/ArticleCard'
import { BlockProps } from '../props'
import { jsonLdListArticleBlock } from '../../ArticlePage/article'

const parseQuery = (tag: string) => {
  const tags = getFiltersFromUrl(tag)
  return {
    brands: tags?.brands ?? '',
    models: tags?.models ?? '',
    cheeses: tags?.cheeses ?? '',
  }
}

export type ListArticlesBlockProps = Omit<BlockProps, 'data'> & {
  data: BlockData
  articles: ArticleUnionPaginator
  withBrands: boolean
}
const ListArticlesBlock: FC<ListArticlesBlockProps> = ({
  data,
  articles,
  withBrands,
}) => {
  const { query } = useRouter()
  const { t } = useTranslation()
  const pageNumber: number = parseInt((query?.page as string) ?? '1', 10) ?? 1
  const ref = useRef<HTMLDivElement>(null)

  const allRoute = withBrands ? routes.articlesBrands : routes.articles
  const allPageRoute = withBrands
    ? routes.articlesBrandsPage
    : routes.articlesPage
  const tagRoute = withBrands ? routes.articlesBrandsTag : routes.articlesTag
  const tagPageRoute = withBrands
    ? routes.articlesBrandsTagPage
    : routes.articlesTagPage

  const [filterState, setFilterState] = useState(
    parseQuery(query?.tag as string)
  )

  useUpdateEffect(() => {
    setFilterState(parseQuery(query?.tag as string))
  }, [query?.tag])

  useUpdateEffect(() => {
    const tag = Object.entries(filterState)
      .filter(([, value]) => !!value && value?.length)
      .reduce(
        (obj, [name, value]) => obj.concat([`${name}_${value}`]),
        [] as string[]
      )
      ?.join('--')

    if (tag) {
      Router.push(Router.getRouteUrl(tagRoute, { tag }), undefined, {
        scroll: false,
      })
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      Router.push(Router.getRouteUrl(allRoute), undefined, {
        scroll: false,
      })
    }
  }, [allRoute, filterState, tagRoute])

  const handleFilterChange = useCallback(
    (e: any, name: string) => {
      setFilterState((before) => ({
        ...before,
        [e.target.name]: e.target.value,
      }))
      tracking.filter(
        t(`listArticles_filter_${name}_label`),
        data?.filters[name]?.[e.target.value]
      )
    },
    [data.filters, t]
  )

  const filters: AllFiltersFormProps = useMemo(
    () => ({
      formikForm: {
        initialValues: filterState,
        onSubmit: () => undefined,
        validateOnChange: true,
        enableReinitialize: false,
      },
      fields: (withBrands
        ? ['brands', 'models', 'cheeses']
        : ['models', 'cheeses']
      )
        ?.filter((f) => !!data?.filters?.[f])
        ?.map((name) => ({
          select: {
            ...formikFieldMocks.basic,
            Component: FormFieldSelect,
            label: t(`listArticles_filter_${name}_label`),
            name,
            placeholder: t(`listArticles_filter_${name}_all`),
            staticLabel: true,
            options: getFilterOptions(t, 'listArticles', name, data?.filters),
            required: false,
            onChange: (e) => handleFilterChange(e, name),
          },
        })),
    }),
    [data?.filters, filterState, handleFilterChange, t, withBrands]
  )

  const paginationProps: PaginationProps = useMemo(
    () => ({
      pageCount: articles?.paginatorInfo?.lastPage,
      pageRangeDisplayed: 2,
      marginPagesDisplayed: 2,
      forcePage: articles?.paginatorInfo?.currentPage - 1,

      hrefBuilder: (pageIndex) => {
        if (pageIndex === 1) {
          return Router.getRouteUrl(query?.tag ? tagRoute : allRoute, {
            ...(query?.tag && { tag: query?.tag }),
          })
        } else {
          return Router.getRouteUrl(query?.tag ? tagPageRoute : allPageRoute, {
            page: pageIndex,
            ...(query?.tag && { tag: query?.tag }),
          })
        }
      },

      onPageChange: (e) => {
        if (e.selected + 1 !== pageNumber && !isNaN(e.selected + 1)) {
          Router.push(
            Router.getRouteUrl(query?.tag ? tagPageRoute : allPageRoute, {
              page: e.selected + 1,
              ...(query?.tag && { tag: query?.tag }),
            }),
            undefined,
            {
              scroll: false,
            }
          )
          ref?.current?.scrollIntoView({
            behavior: 'smooth',
          })
        }
      },
    }),
    [
      allPageRoute,
      allRoute,
      articles?.paginatorInfo?.currentPage,
      articles?.paginatorInfo?.lastPage,
      pageNumber,
      query?.tag,
      tagPageRoute,
      tagRoute,
    ]
  )

  const allProps: AllCardsListingProps = {
    title: t('all_articles'),
    paginationProps,
    allFiltersFormProps: filters,
  }

  const cards: ArticleCardProps[] = articles?.data?.map((article) =>
    transformArticleCard(t, article, t('all_articles'))
  )

  const jsonLd = jsonLdListArticleBlock(t('all_articles'), cards)

  return (
    <>
      {jsonLd && (
        <Head>
          <script
            {...jsonLdScriptProps<ItemList>({
              '@context': 'https://schema.org',
              ...jsonLd,
            })}
          />
        </Head>
      )}
      <AllCardsListing {...allProps} ref={ref}>
        {cards.map((item, i) => {
          return <ArticleCard {...item} key={i} />
        })}
      </AllCardsListing>
    </>
  )
}

export default ListArticlesBlock
