import React, { FC } from 'react'

import { IframeGoodPlanBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import Iframe, { IframeProps } from '../../../components/atoms/Iframe'

export type IframeGoodPlanBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const IframeGoodPlanBlock: FC<IframeGoodPlanBlockProps> = ({ data }) => {
  const props: IframeProps = {
    //
    src: data?.url ?? '',
  }

  return <Iframe {...props} />
}

export default IframeGoodPlanBlock
