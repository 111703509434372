import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PushHpFullWidthBlock as BlockData } from '../../../graphql/generated/api-graphql'
import PictureEdito from '../../../components/molecules/PictureEdito'

import PushHpFullWidthBlockTransform from './transform'

export type PushHpFullWidthBlockProps = {
  data: BlockData
}
const PushHpFullWidthBlock: FC<PushHpFullWidthBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  const props = PushHpFullWidthBlockTransform(t, data)

  return <PictureEdito {...props} />
}

export default PushHpFullWidthBlock
