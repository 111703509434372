import React, { FC, useEffect, useState } from 'react'

import * as SC from './styled'

export type IframeProps = React.IframeHTMLAttributes<HTMLIFrameElement> & {
  height?: number
}

const Iframe: FC<IframeProps> = ({
  className,
  height = 800,
  ...extraProps
}) => {
  const [iframeHeight, setIframeHeight] = useState(800)

  const handleMessage = (event: MessageEvent) => {
    if (
      event.data &&
      event.data.event &&
      event.data.event === 'height' &&
      event.data.height &&
      !isNaN(event.data.height)
    ) {
      setIframeHeight(event.data.height)
    }
  }

  useEffect(() => {
    setIframeHeight(height ?? -1)
    console.log('height', height)
  }, [height])

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [])

  return (
    <SC.Container className={className} $height={iframeHeight}>
      <SC.Iframe {...extraProps} />
    </SC.Container>
  )
}

export default Iframe
