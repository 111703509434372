import styled from 'styled-components'

import { mq, sizes } from '../../../theme'

export enum HeroVariant {
  normal = 'normal',
  positive = 'positive',
}

export const Container = styled.div`
  text-align: center;
`

export const CardsContent = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  padding: 0 2rem;

  @media ${mq(sizes.tablet)} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${mq(sizes.desktop)} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${mq(sizes.scoping)} {
    grid-template-columns: repeat(4, 1fr);
  }

  & article {
    margin-right: auto;
    margin-left: auto;
  }
`

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH3};
  color: ${({ theme }) => theme.colors.arapawa};
  margin: 0 0 2rem 0;
  text-align: center;
`
