import React, { FC } from 'react'
import cx from 'classnames'

import { NavCardProps } from '../NavCard'

import * as SC from './styled'

export type GoodPlansListProps = {
  className?: string
  cards: NavCardProps[]
}

const GoodPlansList: FC<GoodPlansListProps> = (props) => {
  const { className, cards } = props

  return (
    <SC.Container className={cx('GoodPlansList', className)}>
      <SC.List>
        {cards.map((card, index) => (
          <SC.Card key={index} {...card} />
        ))}
      </SC.List>
    </SC.Container>
  )
}

export default GoodPlansList
