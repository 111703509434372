import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  HeroBannerCheesesBlock as HeroBannerBlockData,
  PageTemplate,
} from '../../../graphql/generated/api-graphql'
import HeroBanner, {
  HeroBannerProps,
} from '../../../components/molecules/HeroBanner'
import { BlockProps } from '../props'

import HeroBannerCheesesBlockTransform from './transform'

export type HeroBannerCheesesBlockProps = Omit<BlockProps, 'data'> & {
  data: HeroBannerBlockData
}
const HeroBannerCheesesBlock: FC<HeroBannerCheesesBlockProps> = ({
  data,
  template,
}) => {
  const { t } = useTranslation()
  const heroProps: HeroBannerProps = {
    ...HeroBannerCheesesBlockTransform(t, data),
    ...(template === PageTemplate.HpBrands && {
      brandLogoProps: {
        alt: t('header_alt_logo') ?? '',
        maxWidth: 140,
        width: 140,
        height: 140,
        withHD: true,
        withLazyLoading: false,
        images: [
          {
            src: '/static/assets/images/common/logo-220-min.png',
            size: 140,
          },
          {
            src: '/static/assets/images/common/logo-220-min.webp',
            size: 140,
          },
        ],
      },
    }),
  }

  return <HeroBanner {...heroProps} />
}

export default HeroBannerCheesesBlock
