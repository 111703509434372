import styled from 'styled-components'

import Html from '../../atoms/Html'
import { mq, sizes } from '../../../theme'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.pureWhite};
  padding: 2rem 1.5rem;
  border-radius: 3rem;
  min-height: 200px;

  @media ${mq(sizes.mobile)} {
    padding: 2rem 4rem;
  }

  #easiform {
    @media ${mq(sizes.mobile)} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }

    & #easi_fielddiv_Description {
      @media ${mq(sizes.mobile)} {
        grid-column: 3/1;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;

      & .mandatory {
        display: none;
      }

      & label {
        ${({ theme }) => theme.textStyles.textSmallStrong};
        color: ${(props) => props.theme.colors.bismark};

        &::after {
          content: ' *';
        }
      }

      & input,
      & select,
      & textarea {
        width: 100%;
        border-radius: 0.6rem;
        border: 1px solid ${(props) => props.theme.colors.pattensBlue};
        margin-top: 0.5rem;
        padding-inline: 1rem;
        &:focus {
          outline: none;
        }
      }
      & textarea {
        padding-block: 1rem;
      }

      & input,
      & select {
        height: 5rem;
      }

      & textarea {
        min-height: 16rem;
        resize: vertical;
      }
    }
  }

  #mandatoryfieldmention {
    display: none;
  }

  #validatebutton {
    margin: 3rem auto 0 auto;
    display: flex;
    ${({ theme }) => theme.textStyles.titleH7}
    color: ${(props) => props.theme.colors.pureWhite};
    padding: 1.75rem 1.6rem;
    border-radius: 1rem;
    border: 1px solid ${(props) => props.theme.colors.arapawa};
    background-color: ${(props) => props.theme.colors.arapawa};
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;

    &:hover {
      color: ${(props) => props.theme.colors.arapawa};
      background-color: ${(props) => props.theme.colors.pureWhite};
    }
  }
`

export const Intro = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.gainsboro};
  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  @media ${mq(sizes.mobile)} {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
`

export const Title = styled.p`
  ${({ theme }) => theme.textStyles.textXLarge};
  color: ${(props) => props.theme.colors.matterhorn};
`
export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.textSmall};
  color: ${(props) => props.theme.colors.grey};
  padding-top: 1rem;
`

export const StyledHtml = styled(Html)`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${(props) => props.theme.colors.grey};
  padding-top: 3rem;
`
