import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BrandsBlock as BlockData,
  PageTemplate,
} from '../../../graphql/generated/api-graphql'
import BrandList from '../../../components/molecules/BrandList'
import { BlockProps } from '../props'
import BrandCardsBlock from '../../../components/molecules/BrandCardsBlock'

import {
  BrandsBlockToBrandListTransform,
  BrandsBlockToBrandCardsTransform,
} from './transform'

export type BrandsBlockProps = Omit<BlockProps, 'data'> & {
  data: BlockData
}
const BrandsBlock: FC<BrandsBlockProps> = ({ data, template }) => {
  const { t } = useTranslation()

  if (template === PageTemplate.HpBrands) {
    const brandCardsProps = BrandsBlockToBrandCardsTransform(t, data)
    return <BrandCardsBlock {...brandCardsProps} />
  }

  const brandListProps = BrandsBlockToBrandListTransform(t, data)
  return <BrandList {...brandListProps} />
}

export default BrandsBlock
