import React, { FC, useRef, useState } from 'react'

import RecipeCard, { RecipeCardProps } from '../RecipeCard'
import Icon, { Icons } from '../../atoms/Icon'
import { CardVariant } from '../ContentCard/styled'

import * as SC from './styled'

export type TopCardsBlockProps = MinimalSeoBlock & {
  className?: string
  title?: string
  cards?: RecipeCardProps[]
  moreLabel?: string
  lessLabel?: string
}

const TopCardsBlock: FC<TopCardsBlockProps> = ({
  className,
  htmlTag,
  title,
  htmlTitleTag,
  cards,
  moreLabel,
  lessLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  return (
    <SC.CardsBlock className={className} $isOpen={isOpen} as={htmlTag}>
      <SC.Container>
        {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
        {cards && (
          <SC.Cards ref={ref}>
            {cards?.map((card, i) => (
              <RecipeCard key={i} {...card} variant={CardVariant.Small} />
            ))}
          </SC.Cards>
        )}
        <SC.More onClick={() => setIsOpen((before) => !before)}>
          {isOpen && lessLabel && <span>{lessLabel}</span>}
          {!isOpen && moreLabel && <span>{moreLabel}</span>}
          <Icon
            icon={isOpen ? Icons.circleChevronUp : Icons.circleChevronDown}
          />
        </SC.More>
      </SC.Container>
    </SC.CardsBlock>
  )
}

export default TopCardsBlock
