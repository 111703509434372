import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PositiveFoodPushBlock as Data } from '../../../../../graphql/generated/api-graphql'
import Hero, { HeroProps } from '../../../../../components/molecules/Hero'
import { wediaImagesTransform } from '../../../../Common/wedia'
import { ctaBlockToActionButtonProps } from '../../../../Common/ctaBlockToActionButtonProps'

export type PositiveFoodPushBlockProps = {
  data: Data
}
const PositiveFoodPushBlock: FC<PositiveFoodPushBlockProps> = ({ data }) => {
  const { t } = useTranslation()

  const positiveFoodPushBlockProps: HeroProps | undefined = data
    ? {
        title: data?.title ?? undefined,
        text: data?.body ?? undefined,
        isFullLink: true,
        buttonLabel: data.ctaBlock?.label ?? t('positive_food_consult'),
        linkProps: data?.ctaBlock?.href
          ? ctaBlockToActionButtonProps(data.ctaBlock)
          : undefined,
        imageProps: {
          alt: data.image?.alt ?? ' ',
          maxWidth: 1280,
          width: 1280,
          height: 512,
          withHD: true,
          images: wediaImagesTransform(data.image),
        },
        mobileImageProps: {
          maxWidth: 370,
          width: 370,
          height: 200,
          withHD: true,
          withLazyLoading: false,
          images: data?.image ? wediaImagesTransform(data.image) : undefined,
          alt: data?.title ?? data.image?.alt ?? '',
        },
      }
    : undefined

  return positiveFoodPushBlockProps ? (
    <Hero {...positiveFoodPushBlockProps} />
  ) : null
}

export default PositiveFoodPushBlock
