import React, { FC } from 'react'

import { ActionButtonProps } from '../../atoms/ActionButton'
import { PictureProps } from '../../atoms/Picture'

import * as SC from './styled'

export type PictureCardMessageProps = MinimalSeoBlock & {
  className?: string
  imageProps?: PictureProps
  intro?: string
  title?: string
  text?: string
  actionButtonProps?: ActionButtonProps
}

const PictureCardMessage: FC<PictureCardMessageProps> = (props) => {
  const { className, imageProps, intro, title, text, actionButtonProps } = props

  return (
    <SC.Container className={className}>
      <SC.BackgroundImage {...imageProps} />
      <SC.Content>
        {intro && <SC.Intro>{intro}</SC.Intro>}
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
        {actionButtonProps && <SC.Button {...actionButtonProps} />}
      </SC.Content>
    </SC.Container>
  )
}

export default PictureCardMessage
