import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'

import {
  PageTemplate,
  PositiveFoodSelectedFormatsBlock as Data,
  Format,
} from '../../../../../graphql/generated/api-graphql'
import ProductCardBlock, {
  ProductCardBlockProps,
} from '../../../../../components/molecules/ProductCardBlock'
import { wediaImagesTransform } from '../../../../Common/wedia'
import { ActionButtonVariant } from '../../../../../components/atoms/ActionButton/styled'
import Router from '../../../../../routes/Router'
import routes from '../../../../../routes/definitions'
import { BlockProps } from '../../../props'
import { ProductPopinProps } from '../../../../../components/molecules/ProductPopin'
import productPopinTransform from '../../../../ProductPopin/transform'

export type PositiveFoodSelectedFormatsBlockProps = BlockProps & {
  data: Data
}
const PositiveFoodSelectedFormatsBlock: FC<
  PositiveFoodSelectedFormatsBlockProps
> = ({ data, pagesByTemplate }) => {
  const { t } = useTranslation()

  const [popinData, setPopinData] = useState<ProductPopinProps | undefined>(
    undefined
  )
  const [openOpin, setOpenPopin] = useState(false)

  const handleClose = useCallback(() => {
    setOpenPopin(false)
  }, [])

  const handleLoadOpenPopin = useCallback(
    (product: Format) => {
      const transformed = productPopinTransform({
        t,
        data: { format: product },
      })
      setPopinData(transformed)
      setOpenPopin(true)
    },
    [t]
  )

  const productPopinProps: ProductPopinProps | undefined = useMemo(() => {
    return popinData
      ? {
          ...popinData,
          popin: {
            ...popinData.popin,
            isOpen: openOpin,
            closeHandler: handleClose,
          },
        }
      : undefined
  }, [handleClose, openOpin, popinData])

  const positiveFoodSelectedFormatsBlockProps:
    | ProductCardBlockProps
    | undefined = data
    ? {
        title: data.title ?? undefined,
        tags: data.tags
          ? data.tags.map((tag) => ({
              label: tag.name,
              value: tag.slug,
              href: Router.getRouteUrl(routes.tag, { slug: tag.slug }),
              onClick: () => tracking.tag(tag.name, data.title ?? ''),
            }))
          : undefined,
        ...(pagesByTemplate?.[PageTemplate.HpBrands]?.slug && {
          actionButtonProps: {
            href: pagesByTemplate[PageTemplate.HpBrands].slug,
            text: t('see_more_products'),
            variant: ActionButtonVariant.secondary,
            onClick: () =>
              tracking.cta(t('see_more_products'), data.title ?? undefined),
          },
        }),
        productPopinProps: productPopinProps,
        cards: data.formatBlocks
          ? data.formatBlocks.map((card) => ({
              imageProps: {
                alt: card?.format?.packshot?.alt ?? '',
                maxWidth: 256,
                width: 256,
                withHD: true,
                images: wediaImagesTransform(card?.format?.packshot),
              },
              title: card?.format?.title ?? '',
              titleBack:
                card?.flipTitle ?? card?.format?.flipCardTitle ?? undefined,

              textBack:
                card?.flipBody ?? card?.format?.flipCardBody
                  ? {
                      text: card?.flipBody ?? card?.format?.flipCardBody ?? '',
                    }
                  : undefined,
              actionButtonProps: card?.format
                ? {
                    onClick: () => handleLoadOpenPopin(card.format),
                    label: t('positive_food_view_product'),
                    variant: ActionButtonVariant.yellow,
                  }
                : undefined,
              brandLogoProps: card?.format.mainBrand?.pictureLogo
                ? {
                    alt: card.format.mainBrand.pictureLogo?.alt ?? '',
                    maxWidth: 50,
                    width: 50,
                    withHD: true,
                    images: wediaImagesTransform(
                      card.format.mainBrand.pictureLogo
                    ),
                  }
                : undefined,
            }))
          : undefined,
      }
    : undefined

  return positiveFoodSelectedFormatsBlockProps ? (
    <ProductCardBlock {...positiveFoodSelectedFormatsBlockProps} />
  ) : null
}

export default PositiveFoodSelectedFormatsBlock
