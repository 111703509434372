import styled from 'styled-components'

import NavCard from '../NavCard'
import { mq, sizes } from '../../../theme'

export const Container = styled.div`
  padding: 0 2rem;

  @media ${mq(sizes.tablet)} {
    padding: 0;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
  }
`

export const Card = styled(NavCard)``
