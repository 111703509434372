import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PushHpThumbnailBlock as BlockData } from '../../../graphql/generated/api-graphql'
import BlogCardsBlock from '../../../components/molecules/BlogCardsBlock'

import PushHpThumbnailBlockTransform from './transform'

export type PushHpBlockProps = {
  data: BlockData
}
const PushHpThumbnailBlock: FC<PushHpBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  const props = PushHpThumbnailBlockTransform(t, data)

  return <BlogCardsBlock {...props} />
}

export default PushHpThumbnailBlock
