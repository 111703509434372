import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'
import { useRouter } from 'next/router'

import {
  ArticleType,
  ArticleUnion,
  ArticleUnionFourItemsBlock as Data,
  PageTemplate,
  Tag,
} from '../../../graphql/generated/api-graphql'
import CardsBlock, {
  CardsBlockProps,
} from '../../../components/molecules/CardsBlock'
import { transformTagForCloud } from '../../Common/tag'
import Router, { routes } from '../../../routes/Router'
import { transformArticleBigCard } from '../../ArticleCard/transformArticleBigCard'
import { transformArticleCard } from '../../ArticleCard/transformArticleCard'
import { Icons } from '../../../components/atoms/Icon'
import { BlockProps } from '../props'
import { isHomePage } from '../../Common/routes'

export type ArticleUnionFourItemsBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const ArticleUnionFourItemsBlock: FC<ArticleUnionFourItemsBlockProps> = ({
  data,
  pagesByTemplate,
  template,
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  const type =
    data?.articleUnions?.[0] && 'type' in data.articleUnions[0]
      ? data.articleUnions[0].type
      : undefined

  const props: CardsBlockProps = {
    title: data?.title ?? '',
    tags: data?.tags?.map((tag: Tag) =>
      transformTagForCloud(tag, `${template} - ${data.title}`)
    ),
    highlighted: transformArticleBigCard(
      t,
      data?.articleUnions?.[0],
      data.title ?? undefined,
      isHomePage(router.pathname)
    ),
    cards: data?.articleUnions
      ?.slice(1)
      ?.map((item: ArticleUnion) =>
        transformArticleCard(
          t,
          item,
          data?.title ?? '',
          isHomePage(router.pathname)
        )
      ),
    actionButtonProps: {
      label: t('see_more_articles'),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
      onClick: () => {
        tracking.cta(
          t('see_more_articles') ?? undefined,
          data?.title ?? undefined
        )
      },
      ...(type === ArticleType.Cheese &&
      template !== PageTemplate.HpCheesesCulture
        ? {
            href: pagesByTemplate?.[PageTemplate.HpCheesesCulture]?.slug,
          }
        : type === ArticleType.Health
        ? {
            href: pagesByTemplate?.[PageTemplate.PositiveFood]?.slug,
          }
        : // default
          {
            href: Router.getRouteUrl(routes.articles),
          }),
    },
  }

  return <CardsBlock {...props} />
}

export default ArticleUnionFourItemsBlock
