import React, { FC, forwardRef, ForwardRefRenderFunction } from 'react'

import { MainLayoutProps } from '../../../layouts/MainLayout'
import AllFiltersForm, {
  AllFiltersFormProps,
} from '../../../components/forms/AllCheesesFiltersForm'
import Pagination, {
  PaginationProps,
} from '../../../components/molecules/Pagination'

import * as SC from './styled'

export type AllCardsListingProps = MainLayoutProps & {
  title?: string
  allFiltersFormProps?: AllFiltersFormProps
  paginationProps?: PaginationProps
  children?: React.ReactNode
}

const AllCardsListingRenderer: ForwardRefRenderFunction<
  any,
  AllCardsListingProps
> = ({ title, allFiltersFormProps, paginationProps, children }, ref) => {
  return (
    <SC.Container ref={ref}>
      <SC.Content>
        {title && <SC.Title>{title}</SC.Title>}
        {allFiltersFormProps && <AllFiltersForm {...allFiltersFormProps} />}
        <SC.CardsContainer>{children}</SC.CardsContainer>
      </SC.Content>
      <SC.PaginationContainer>
        {paginationProps && <Pagination {...paginationProps} />}
      </SC.PaginationContainer>
    </SC.Container>
  )
}

const AllCardsListing: FC<
  AllCardsListingProps & { ref?: React.LegacyRef<any> }
> = forwardRef(AllCardsListingRenderer)

export default AllCardsListing
