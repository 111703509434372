import React, { FC } from 'react'

import DropdownCheckboxPart, {
  DropdownCheckboxPartProps,
} from '../../forms/DropdownCheckboxPart'
import Link from '../../atoms/Link'
import { FormikFormProps } from '../../form/FormikForm'

import * as SC from './styled'

export type TableIndexCheesesProps = MinimalSeoBlock & {
  cheeses?: {
    name?: string
    typeLait?: string
    origin?: string
    typePate?: string
    gout?: string
    link?: string
  }[]
  cheesesLabel?: string
  dropdowns?: DropdownCheckboxPartProps[]
  formikForm: FormikFormProps
}

const TableIndexCheeses: FC<TableIndexCheesesProps> = ({
  cheeses,
  dropdowns,
  cheesesLabel,
  formikForm,
}) => {
  // filtrer par odre alphabetique
  function sortOn(property: any) {
    return function (a: any, b: any) {
      if (a[property] < b[property]) {
        return -1
      } else if (a[property] > b[property]) {
        return 1
      } else {
        return 0
      }
    }
  }

  return (
    <SC.TableContainer>
      <SC.Form {...formikForm}>
        <SC.Array>
          <SC.HeadArray>
            <tr>
              <th>{cheesesLabel}</th>
              {dropdowns &&
                dropdowns.map((item, i) => {
                  return (
                    <th key={i}>
                      <DropdownCheckboxPart {...item} />
                    </th>
                  )
                })}
            </tr>
          </SC.HeadArray>

          <SC.BodyArray>
            {cheeses?.sort(sortOn('name')).map((item, i) => {
              const previousTitle = cheeses?.sort(sortOn('name'))[i - 1]?.name

              return (
                <tr
                  key={i}
                  className={
                    previousTitle &&
                    previousTitle.substr(0, 1).toLowerCase() !==
                      item?.name?.substr(0, 1).toLowerCase()
                      ? 'break'
                      : ''
                  }
                >
                  <td>
                    <Link href={item.link}>{item.name}</Link>
                  </td>
                  <td>{item.typeLait && item.typeLait}</td>
                  <td>{item.origin && item.origin}</td>
                  <td>{item.typePate && item.typePate}</td>
                  <td>{item.gout && item.gout}</td>
                </tr>
              )
            })}
          </SC.BodyArray>
        </SC.Array>
      </SC.Form>
    </SC.TableContainer>
  )
}

export default TableIndexCheeses
