import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PlateFourItemsBlock as BlockData } from '../../../graphql/generated/api-graphql'
import CardsBlock from '../../../components/molecules/CardsBlock'

import PlateFourItemsBlockTransform from './transform'

export type PlateFourItemsBlockProps = {
  data: BlockData
}
const PlateFourItemsBlock: FC<PlateFourItemsBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  const props = PlateFourItemsBlockTransform(t, data)

  return <CardsBlock {...props} />
}

export default PlateFourItemsBlock
