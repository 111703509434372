import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'

export const CardsBlock = styled.section<{ $isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 1.5rem;
  @media ${mq(sizes.tablet)} {
    padding: 0;
  }
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      ${Cards} {
        & > *:nth-child(n + 2) {
          display: none;
        }
        @media ${mq(sizes.tablet)} {
          & > *:nth-child(n + 2) {
            display: flex;
          }
        }
      }
    `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 2rem 1.5rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.chablis};
  @media ${mq(sizes.tablet)} {
    padding: 2rem 3rem;
    border-radius: 4rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  flex-grow: 1;
  margin: 0;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH2};
  }
`

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  margin-top: 2rem;
  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    align-items: stretch;
  }
  @media ${mq(sizes.desktop)} {
    margin-top: 3rem;
    gap: 2.4rem;
  }
`

export const More = styled.div`
  ${({ theme }) => theme.textStyles.titleH7};
  color: ${({ theme }) => theme.colors.arapawa};
  display: flex;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  gap: 1.5rem;
  align-items: center;
  & svg {
    width: 2.8rem;
    height: 2.8rem;
  }
  & span {
    padding-top: 0.4rem;
  }
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
