import React, { FC, useState } from 'react'

import { PictureProps } from '../../atoms/Picture'
import { LinkProps } from '../../atoms/Link'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { HtmlProps } from '../../atoms/Html'

import * as SC from './styled'

export type ProductCardProps = MinimalSeoBlock & {
  className?: string
  imageProps: PictureProps
  brandLogoProps?: PictureProps
  brandLogoLinkProps?: LinkProps
  title?: string
  titleBack?: string
  textBack?: HtmlProps
  actionButtonProps?: ActionButtonProps
}

const ProductCard: FC<ProductCardProps> = ({
  className,
  htmlTag,
  imageProps,
  brandLogoProps,
  brandLogoLinkProps,
  title,
  htmlTitleTag,
  titleBack,
  textBack,
  actionButtonProps,
}) => {
  const [active, setActive] = useState(false)

  return (
    <SC.ProductCard className={className} as={htmlTag}>
      <SC.Container $active={active}>
        <SC.CardBack onClick={() => setActive(true)}>
          <SC.BackgroundImage {...imageProps} placeholder />
          <SC.Card>
            <SC.Bezel
              viewBox="0 0 335 11"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path d="M0 0.375V10.375H168.303C99.7883 10.3705 38.8536 6.45373 0 0.375ZM168.537 10.375H335V0.658797C296.078 6.57536 235.98 10.3706 168.537 10.375Z" />
            </SC.Bezel>
            {brandLogoProps && (
              <SC.LogoContainer {...brandLogoLinkProps}>
                <SC.Logo {...brandLogoProps} />
              </SC.LogoContainer>
            )}
            <SC.Content>
              {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
            </SC.Content>
          </SC.Card>
        </SC.CardBack>
        <SC.CardFront $active={active}>
          <SC.Close onClick={() => setActive(false)}>
            <SC.StyledIcon icon="close" />
          </SC.Close>
          <SC.TitleBack>{titleBack}</SC.TitleBack>
          <SC.TextBack {...textBack} />
          <SC.StyledButton {...actionButtonProps} />
        </SC.CardFront>
      </SC.Container>
    </SC.ProductCard>
  )
}

export default ProductCard
