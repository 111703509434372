import { app } from '../../configuration'
import { PlatePaginator } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'

export const jsonLdPlates = (plates: PlatePaginator) => {
  return `{
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": ${plates.data.map(
      (plate, index) =>
        `{
        "@type": "ListItem",
        "name": "${plate.title}",
        "position": "${index}",
        "item": "${
          app.APP_URL + Router.getRouteUrl(routes.plate, { slug: plate.slug })
        }"
      }`
    )}
  }`
}
