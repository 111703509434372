import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'

import {
  PositiveFoodDynamicRecipesBlock as Data,
  Recipe,
} from '../../../../../graphql/generated/api-graphql'
import CardsBlock, {
  CardsBlockProps,
} from '../../../../../components/molecules/CardsBlock'
import Router from '../../../../../routes/Router'
import routes from '../../../../../routes/definitions'
import { transformRecipeCard } from '../../../../RecipeCard/transformRecipeCard'
import { transformRecipeBigCard } from '../../../../RecipeCard/transformRecipeBigCard'
import { Icons } from '../../../../../components/atoms/Icon'
import { ctaBlockToActionButtonProps } from '../../../../Common/ctaBlockToActionButtonProps'

export type PositiveFoodDynamicRecipesBlockProps = {
  data: Data
}
const PositiveFoodDynamicRecipesBlock: FC<
  PositiveFoodDynamicRecipesBlockProps
> = ({ data }) => {
  const { t } = useTranslation()

  const bigRecipe =
    data.recipes && (data.recipes.length == 1 || data.recipes.length > 2)
      ? transformRecipeBigCard(t, data?.recipes?.[0], data.title ?? undefined)
      : undefined

  const smallRecipes =
    data?.recipes && data?.recipes?.length > 0
      ? data.recipes
          .map((item: Recipe) =>
            transformRecipeCard(t, item, data.title ?? undefined)
          )
          ?.slice(bigRecipe ? 1 : 0)
      : undefined

  const positiveFoodDynamicRecipesBlockProps: CardsBlockProps | undefined =
    data && data?.recipes && data?.recipes?.length > 0
      ? {
          title: data.title ?? undefined,
          tags: data.tags
            ? data.tags.map((tag) => ({
                label: tag.name,
                value: tag.slug,
                href: Router.getRouteUrl(routes.tag, { slug: tag.slug }),
              }))
            : undefined,
          highlighted: bigRecipe,
          cards: smallRecipes,
          actionButtonProps:
            data.ctaBlock?.href && data.ctaBlock?.label
              ? {
                  ...ctaBlockToActionButtonProps(data.ctaBlock),
                  iconProps: {
                    icon: Icons.arrowRight,
                  },
                  iconPosition: 'right',
                  onClick: () =>
                    tracking.cta(
                      data.ctaBlock?.label ?? undefined,
                      data.title ?? undefined
                    ),
                }
              : undefined,
        }
      : undefined

  return positiveFoodDynamicRecipesBlockProps ? (
    <>
      <CardsBlock {...positiveFoodDynamicRecipesBlockProps} />
    </>
  ) : null
}

export default PositiveFoodDynamicRecipesBlock
