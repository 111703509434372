import React, { FC } from 'react'

import ActionButton, { ActionButtonProps } from '../../atoms/ActionButton'
import { PictureProps } from '../../atoms/Picture'

import { PictureEditoVariant } from './styled'
import * as SC from './styled'

export type PictureEditoProps = MinimalSeoBlock & {
  className?: string
  imageProps?: PictureProps
  mobileImageProps?: PictureProps
  intro?: string
  title?: string
  text?: string
  actionButtonProps?: ActionButtonProps
  variant?: PictureEditoVariant
}

const PictureEdito: FC<PictureEditoProps> = (props) => {
  const {
    className,
    imageProps,
    mobileImageProps,
    intro,
    title,
    text,
    actionButtonProps,
    variant,
  } = props

  return (
    <SC.Container className={className}>
      <SC.Wrapper>
        {!mobileImageProps && <SC.BackgroundImage {...imageProps} />}
        {mobileImageProps && (
          <>
            <SC.DesktopBackgroundImage {...imageProps} />
            <SC.MobileBackgroundImage {...imageProps} />
          </>
        )}
        <SC.Content>
          {variant && <SC.IntroLine $variant={variant}></SC.IntroLine>}
          {intro && variant && <SC.Intro $variant={variant}>{intro}</SC.Intro>}
          {title && <SC.Title>{title}</SC.Title>}
          {text && <SC.Text>{text}</SC.Text>}
          {actionButtonProps && <ActionButton {...actionButtonProps} />}
        </SC.Content>
      </SC.Wrapper>
    </SC.Container>
  )
}

export default PictureEdito
