import styled from 'styled-components'

import Icon from '../../atoms/Icon'

export const Dropdown = styled.div`
  position: relative;
`

export const StyledIcon = styled(Icon)<{ $color: boolean }>`
  color: ${(props) => props.$color && props.theme.colors.ruby};
  margin-right: 0.8rem;
`

export const StyledArrowIcon = styled(Icon)<{ $isOpen: boolean }>`
  margin-left: 1.6rem;
  transform: rotate(${({ $isOpen }) => ($isOpen ? '180deg' : 0)});
`

export const Title = styled.button`
  background: transparent;
  ${({ theme }) => theme.textStyles.textSmallStrong};
  color: ${(props) => props.theme.colors.arapawa};
  display: flex;
  align-items: center;
`

export const Content = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  width: 100%;
  padding: 1.5rem;
  top: 3.8rem;
  background-color: ${(props) => props.theme.colors.pureWhite};
  position: absolute;
  box-shadow: 0 4px 10px rgb(0 0 0 / 15%);
  border-radius: 0 0 1rem 1rem;
  flex-direction: column;
  gap: 1rem;

  & label p {
    ${({ theme }) => theme.textStyles.textSmall};
  }
`
