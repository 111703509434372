import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { RecipeTopFiveBlock as Data } from '../../../graphql/generated/api-graphql'
import TopCardsBlock, {
  TopCardsBlockProps,
} from '../../../components/molecules/TopCardsBlock'
import { transformRecipeSmallCard } from '../../RecipeCard/transformRecipeSmallCard'

export type RecipeTopFiveBlockProps = {
  data: Data
}
const RecipeTopFiveBlock: FC<RecipeTopFiveBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  //console.log("top",data)
  const props: TopCardsBlockProps = {
    title: t('topFiveRecipesTitle'),
    cards: data?.recipes?.map((item, index) => {
      return transformRecipeSmallCard(item, index, t, t('topFiveRecipesTitle'))
    }),
    moreLabel: t('topFiveRecipesMore'),
    lessLabel: t('topFiveRecipesLess'),
  }

  return <TopCardsBlock {...props} />
}

export default RecipeTopFiveBlock
