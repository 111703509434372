import { ItemList } from 'schema-dts'

import { CheeseCardBlockProps } from '../../components/molecules/CheeseCardBlock'
import APP from '../../configuration/app'

export const jsonLdListCheeseBlock = (
  title: string,
  cheeses: CheeseCardBlockProps
): ItemList => {
  return {
    '@type': 'ItemList',
    name: title,
    numberOfItems: cheeses?.cheeseCards?.length,
    itemListElement: cheeses?.cheeseCards?.map((cheese, index) => ({
      '@type': 'ListItem',
      position: index,
      url: APP.APP_URL + cheese?.linkProps?.href,
      image: cheese?.imageProps?.images?.[0]?.src || '',
      name: cheese?.title,
    })),
  }
}
