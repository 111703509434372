import React, { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import {
  CulinaryFolder,
  CulinaryFolderPaginator,
  CulinaryFoldersListBlock as Data,
} from '../../../graphql/generated/api-graphql'
import CardsBlock, {
  CardsBlockProps,
} from '../../../components/molecules/CardsBlock'
import { transformFolderCard } from '../../FolderCard/transformFolderCard'
import Router, { routes } from '../../../routes/Router'
import { jsonLdCulinaryFolders } from '../../FolderCard/folder'

export type CulinaryFoldersListBlockProps = {
  data: Data
  folders?: CulinaryFolderPaginator
}
const CulinaryFoldersListBlock: FC<CulinaryFoldersListBlockProps> = ({
  data,
  folders,
}) => {
  const { query } = useRouter()
  const { t } = useTranslation()
  const parsedPageNumber = parseInt((query?.page as string) ?? '1', 10)
  const pageNumber: number = !isNaN(parsedPageNumber) ? parsedPageNumber : 1
  const ref = useRef<HTMLDivElement>(null)

  const props: CardsBlockProps = {
    title: data?.title ?? '',
    cards: folders?.data?.map((item: CulinaryFolder) =>
      transformFolderCard(t, item, data?.title ?? undefined)
    ),
    ...(folders?.data && {
      jsonLd: jsonLdCulinaryFolders(folders),
    }),
    paginationProps: {
      pageCount: folders?.paginatorInfo?.lastPage ?? 1,
      pageRangeDisplayed: 2,
      marginPagesDisplayed: 2,
      forcePage: (folders?.paginatorInfo?.currentPage ?? 1) - 1,

      hrefBuilder: (pageIndex) => {
        if (pageIndex === 1) {
          return Router.getRouteUrl(routes.folders)
        } else {
          return Router.getRouteUrl(routes.foldersPage, { page: pageIndex })
        }
      },

      onPageChange: (e) => {
        if (e.selected + 1 !== pageNumber) {
          Router.push(
            Router.getRouteUrl(routes.foldersPage, { page: e.selected + 1 }),
            undefined,
            {
              scroll: false,
            }
          )
          ref?.current?.scrollIntoView({
            behavior: 'smooth',
          })
        }
      },
    },
  }

  return <CardsBlock {...props} containerRef={ref} />
}

export default CulinaryFoldersListBlock
