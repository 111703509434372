import styled from 'styled-components'

import Picture from '../../atoms/Picture'
import { mq, sizes } from '../../../theme'

export const Container = styled.div``

export const Content = styled.div`
  position: relative;

  @media ${mq(sizes.tablet)} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
  }
`

export const TextContent = styled.div`
  width: 100%;
  position: relative;
  padding: 1rem 1rem 3rem 1rem;
  z-index: 1;

  @media ${mq(sizes.tablet)} {
    background-color: ${({ theme }) => theme.colors.pureWhite};
    width: 50%;
    padding: 4rem;
    border-radius: 2rem;
  }
`

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH3};
  color: ${({ theme }) => theme.colors.ruby};
  margin: 0 0 1.5rem 0;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH2};
    margin: 0 0 2rem 0;
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.arapawa};
  margin: 0 0 1.5rem 0;

  @media ${mq(sizes.tablet)} {
    margin: 0 0 2rem 0;
  }
`

export const BackgroundImage = styled(Picture)`
  height: 100%;
  width: 100%;

  z-index: 0;

  & img {
    border-radius: 2rem;
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
  }

  @media ${mq(sizes.tablet)} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;

    & img {
      border-radius: 3rem;
    }
  }
`
