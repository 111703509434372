import tracking, { TrackingCardPosition } from 'src/tracking'

import {
  Cheese,
  CheeseType,
  Maybe,
  WediaImage,
} from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { CheeseCardProps } from '../../components/molecules/CheeseCard'
import { CardVariant } from '../../components/molecules/ContentCard/styled'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { CardContentType } from '../../components/molecules/ContentCard'

export const transformCheeseCard = (
  _t: any,
  data?: Cheese | Maybe<Cheese>,
  aopImage?: WediaImage,
  blockName?: string
): CheeseCardProps => ({
  title: data?.title,
  htmlTitleTag: 'h3',
  ...(data?.slug && {
    linkProps: {
      href: Router.getRouteUrl(routes.cheese, { slug: data?.slug }),
      onClick: () =>
        tracking.cards.block(
          blockName,
          CardContentType.Cheese,
          data.title,
          TrackingCardPosition.CONTENT
        ),
    },
  }),
  type: CardContentType.Cheese,
  variant: CardVariant.Cheese,
  imageProps: {
    maxWidth: 450,
    withHD: true,
    withLazyLoading: true,
    images: wediaImagesTransform(data?.thumbnail01 ?? data?.pictureHeader),
    placeholder: true,
    alt:
      data?.title ?? data?.thumbnail01?.alt ?? data?.pictureHeader?.alt ?? '',
  },
  ...((!!data?.brand?.pictureLogo ||
    (data?.type === CheeseType.Aop && aopImage)) && {
    brandLogoProps: {
      alt: data?.brand?.pictureLogo?.alt ?? ' ',
      maxWidth: 80,
      width: 80,
      height: 80,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(
        data?.type === CheeseType.Aop ? aopImage : data?.brand?.pictureLogo
      ),
    },
  }),
  tags: [
    ...(data?.tagRegion
      ? [
          {
            label: data?.tagRegion?.name,
            variant: TagVariant.brand,
          },
        ]
      : []),
  ].slice(0, 2),
  text: data?.tagMilk ? data?.tagMilk?.name : '',
})
