import { FC } from 'react'
import tracking from 'src/tracking'

import { PositiveFoodCardsBlock as Data } from '../../../../../graphql/generated/api-graphql'
import {
  wediaImageToPoster,
  wediaImagesTransform,
} from '../../../../Common/wedia'
import BlogCardsBlock, {
  BlogCardsBlockProps,
} from '../../../../../components/molecules/BlogCardsBlock'
import { ctaBlockToActionButtonProps } from '../../../../Common/ctaBlockToActionButtonProps'
import { ActionButtonVariant } from '../../../../../components/atoms/ActionButton/styled'
import { BlogCardProps } from '../../../../../components/molecules/BlogCard'
import {
  BlogCardImagePosition,
  BlogCardSize,
} from '../../../../../components/molecules/BlogCard/styled'
import { colors } from '../../../../../theme'
import { BlogCardsBlockSpacing } from '../../../../../components/molecules/BlogCardsBlock/types'
import { Icons } from '../../../../../components/atoms/Icon'
import { VideoBlockProps } from '../../../../../components/molecules/VideoBlock'

export type PositiveFoodCardsBlockProps = {
  data: Data
}
const PositiveFoodCardsBlock: FC<PositiveFoodCardsBlockProps> = ({ data }) => {
  // const { t } = useTranslation()

  const blogCardsProps: BlogCardsBlockProps | undefined = data
    ? {
        title: data?.title ?? '',
        spacing: BlogCardsBlockSpacing.PositiveFood,
        cards:
          data?.cards?.map((card, index) =>
            card?.video && card.video?.sources && card.video.sources.length > 0
              ? ({
                  title: card?.title ?? undefined,
                  htmlProps: card?.body
                    ? {
                        text: card?.body,
                      }
                    : undefined,
                  ...(card?.ctaBlock && {
                    actionButtonProps: {
                      ...ctaBlockToActionButtonProps(card?.ctaBlock),
                      variant: ActionButtonVariant.secondary,
                      onClick: () =>
                        tracking.cta(
                          card?.ctaBlock?.label ?? undefined,
                          card?.title ?? data?.title ?? undefined
                        ),
                    },
                  }),
                  videoProps: {
                    videoCardPosition:
                      index % 2
                        ? BlogCardImagePosition.pictureRight
                        : BlogCardImagePosition.pictureLeft,
                    controls: true,
                    sources:
                      card.video.sources.map((source) => ({
                        src: source?.source ?? '',
                        type: source?.mimeType ?? '',
                      })) ?? [],
                    ...(card?.image && {
                      poster: wediaImageToPoster(card.image),
                    }),
                  },
                } as VideoBlockProps)
              : ({
                  size: BlogCardSize.normal,
                  imagePosition:
                    index % 2
                      ? BlogCardImagePosition.pictureRight
                      : BlogCardImagePosition.pictureLeft,
                  backgroundColor: colors.pureWhite,
                  titleColor: colors.killarney,
                  title: card?.subTitle ?? '',
                  subtitle: card?.title ?? '',
                  htmlProps: {
                    text: card?.body ?? '',
                  },
                  imageProps: card?.image
                    ? {
                        alt: card?.image?.alt ?? ' ',
                        maxWidth: 440,
                        width: 440,
                        height: 440,
                        withHD: true,
                        images: wediaImagesTransform(card?.image),
                      }
                    : undefined,
                  ...(card?.ctaBlock && {
                    actionButtonProps: {
                      ...ctaBlockToActionButtonProps(card?.ctaBlock),
                      variant: ActionButtonVariant.secondary,
                      iconProps: {
                        icon: Icons.arrowRight,
                      },
                      iconPosition: 'right',
                      onClick: () =>
                        tracking.cta(
                          card?.ctaBlock?.label ?? undefined,
                          card?.title ?? data?.title ?? undefined
                        ),
                    },
                  }),
                } as BlogCardProps)
          ) ?? [],
      }
    : undefined

  return <BlogCardsBlock {...blogCardsProps} />
}

export default PositiveFoodCardsBlock
