import React, { FC } from 'react'

import * as SC from './styled'

export type HtmlTitleH1Props = {
  className?: string
  title?: string
  isGeneric?: boolean
}

const HtmlTitleH1: FC<HtmlTitleH1Props> = ({ className, title, isGeneric }) => {
  return (
    <SC.Container className={className} $isGeneric={isGeneric}>
      {title && <SC.Title>{title}</SC.Title>}
    </SC.Container>
  )
}

export default HtmlTitleH1
