import styled from 'styled-components'

import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'
import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'

export const BrandList = styled.div`
  padding: 3rem 0;
  background-color: ${({ theme }) => theme.colors.chablis};
  border-radius: 4rem;
  overflow: hidden;
  text-align: center;
  margin: 0 1.5rem;

  @media ${mq(sizes.desktop)} {
    margin: 0;
  }
`

export const TextsContainer = styled.div`
  text-align: center;
  padding: 0 1.2rem;
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2}
  margin: 3rem 0 1rem 0;
  color: ${({ theme }) => theme.colors.arapawa};
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textLarge}
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.arapawa};
`

export const PictureList = styled.div`
  width: 100%;
  padding: 2rem 1.2rem;
  overflow: auto visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${mq(sizes.desktop)} {
    padding: 2rem 0;
  }
`

export const RowContainer = styled.div`
  width: max-content;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;

  @media ${mq(sizes.desktop)} {
    width: 100%;
  }
`

export const Row = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 5.6rem;
`

export const StyledLogoPicture = styled(Picture)`
  & img {
    width: 5rem;
  }
`

export const ContentLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
`

export const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.atomicTangerine};
  width: 9rem;
  height: 0.1rem;
`

export const StyledPicture = styled(Picture)`
  width: 7.5rem;
  height: 7.5rem;
  padding: 1rem;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLink = styled(Link)`
  width: 7.5rem;
  height: 7.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.pureWhite};
`

export const Button = styled(ActionButton)`
  margin-top: 3rem;
`
