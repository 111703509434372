import { FC } from 'react'

import { TagProps } from '../../atoms/Tag'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { HeroProps } from '../Hero'
import { ProductCardProps } from '../ProductCard'
import ProductPopin, { ProductPopinProps } from '../ProductPopin'

import * as SC from './styled'

export type ProductCardBlockProps = MinimalSeoBlock & {
  className?: string
  title?: string
  tags?: TagProps[]
  highlighted?: HeroProps
  cards?: ProductCardProps[]
  actionButtonProps?: ActionButtonProps
  productPopinProps?: ProductPopinProps
}

const ProductCardBlock: FC<ProductCardBlockProps> = ({
  className,
  htmlTag,
  title,
  htmlTitleTag,
  tags,
  cards,
  actionButtonProps,
  productPopinProps,
}) => {
  return (
    <>
      <SC.CardsBlock className={className} as={htmlTag}>
        {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
        {tags && <SC.Tags tags={tags} />}
        {cards && (
          <SC.Cards>
            {cards?.map((card, i) => {
              return <SC.StyledProductCard key={i} {...card} />
            })}
          </SC.Cards>
        )}
        {actionButtonProps && <SC.Button {...actionButtonProps} />}
      </SC.CardsBlock>
      {productPopinProps && <ProductPopin {...productPopinProps} />}
    </>
  )
}

export default ProductCardBlock
