import tracking from 'src/tracking'

import {
  Plate,
  PlateFourItemsBlock,
} from '../../../graphql/generated/api-graphql'
import { Icons } from '../../../components/atoms/Icon'
import { CardsBlockProps } from '../../../components/molecules/CardsBlock'
import { transformPlateBigCard } from '../../PlateCard/transformPlateBigCard'
import { transformPlateCard } from '../../PlateCard/transformPlateCard'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'

const PlateFourItemsBlockTransform = (
  t: any,
  data: PlateFourItemsBlock
): CardsBlockProps => ({
  title: data?.title ?? '',
  highlighted: transformPlateBigCard(
    t,
    data?.plates?.[0],
    data?.title ?? undefined
  ),
  cards: data?.plates
    ?.slice(1)
    ?.map((item: Plate) =>
      transformPlateCard(t, item, data?.title ?? undefined)
    ),
  ...(data?.ctaBlock?.href && {
    actionButtonProps: {
      ...ctaBlockToActionButtonProps(data?.ctaBlock),
      onClick: () =>
        tracking.cta(
          data?.ctaBlock?.label ?? undefined,
          data?.title ?? undefined
        ),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
    },
  }),
})

export default PlateFourItemsBlockTransform
