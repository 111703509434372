import styled from 'styled-components'

import FormFieldSelect from '../../form/fields/FormFieldSelect'
import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'

export const FAQ = styled.section`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 2rem 1.5rem;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media ${mq(sizes.tablet)} {
    padding: 2rem 4rem;
  }
`

export const Title = styled.span`
  ${({ theme }) => theme.textStyles.textLarge};
  color: ${({ theme }) => theme.colors.arapawa};
`
export const Select = styled(FormFieldSelect)`
  width: auto;
  align-self: flex-start;
`
export const Themes = styled.div``
export const Contact = styled(Html)``
