import tracking from 'src/tracking'

import { wediaImagesTransform } from '../../Common/wedia'
import { BrandsBlock } from '../../../graphql/generated/api-graphql'
import { BrandListProps } from '../../../components/molecules/BrandList'
import Router, { routes } from '../../../routes/Router'
import { Icons } from '../../../components/atoms/Icon'
import { BrandCardsBlockProps } from '../../../components/molecules/BrandCardsBlock'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'

export const BrandsBlockToBrandCardsTransform = (
  _t: any,
  data: BrandsBlock
): BrandCardsBlockProps => ({
  title: data?.title ?? '',
  cards: data?.brands?.map((brand) => ({
    text: brand?.introductionTitle ?? '',
    buttonLabel: 'EN SAVOIR PLUS',
    linkProps: {
      href: Router.getRouteUrl(routes.brand, { slug: brand?.slug }),
      title: brand?.name,
      onClick: () => tracking.cta('EN SAVOIR PLUS', data?.title ?? undefined),
    },
    brandLogoProps: {
      width: 75,
      maxWidth: 150,
      withHD: true,
      withLazyLoading: true,
      images: brand?.pictureLogoRound
        ? wediaImagesTransform(brand.pictureLogoRound)
        : undefined,
      alt: brand?.name,
    },
  })),
  ...(data?.ctaBlock?.href && {
    actionButtonProps: {
      ...ctaBlockToActionButtonProps(data?.ctaBlock),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
    },
  }),
})

export const BrandsBlockToBrandListTransform = (
  _t: any,
  data: BrandsBlock
): BrandListProps => ({
  title: data?.title ?? '',
  text: data?.introduction ?? '',
  logoPictureProps: {
    pictureProps: {
      images: [
        {
          src: '/static/assets/images/common/logo-220-min.png',
          size: 250,
        },
        {
          src: '/static/assets/images/common/logo-220-min.webp',
          size: 250,
        },
      ],
      alt: _t('seo_logo_alt'),
    },
  },
  brandsList: data?.brands?.map((brand) => ({
    linkProps: {
      href: Router.getRouteUrl(routes.brand, { slug: brand?.slug }),
      title: brand?.name,
    },
    pictureProps: {
      maxWidth: 75,
      width: 75,
      height: 75,
      withHD: true,
      withLazyLoading: true,
      images: brand?.pictureLogoRound
        ? wediaImagesTransform(brand.pictureLogoRound)
        : undefined,
      alt: brand?.name,
    },
  })),
  ...(data?.ctaBlock?.href && {
    actionButtonProps: {
      ...ctaBlockToActionButtonProps(data?.ctaBlock),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
      onClick: () =>
        tracking.cta(
          data?.ctaBlock?.label ?? undefined,
          data?.title ?? undefined
        ),
    },
  }),
})
