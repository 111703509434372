import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Link from '../../atoms/Link'
import Picture from '../../atoms/Picture'

export const Slider = styled.div`
  position: relative;
  display: flex;
  padding: 0 1.5rem;
  flex-direction: column;
  @media ${mq(sizes.tablet)} {
    height: 51.2rem;
    justify-content: center;
    padding: 0;
  }
`

export const SwiperContainer = styled.div`
  width: 100%;
  border-radius: 2rem 2rem 0 0;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
  --swiper-navigation-size: 20px;
  --swiper-theme-color: ${({ theme }) => theme.colors.arapawa};
  height: 20rem;
  user-select: none;
  @media ${mq(sizes.tablet)} {
    position: absolute;
    border-radius: 3rem;
    height: 51.2rem;
  }
`

export const DesktopImage = styled(Picture)`
  display: none;
  @media ${mq(sizes.tablet)} {
    width: 100%;
    display: flex;
    height: 51.2rem;
  }
  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`
export const MobileImage = styled(Picture)`
  width: 100%;
  display: flex;
  height: 20rem;
  @media ${mq(sizes.tablet)} {
    display: none;
  }
  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

const cardStyles = css`
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1;
  border-radius: 0 0 2rem 2rem;

  @media ${mq(sizes.tablet)} {
    border-radius: 2rem;
    margin: auto 4rem;
    width: 30rem;
  }
`

export const Card = styled.div`
  ${cardStyles}
`
export const CardLink = styled(Link)`
  ${cardStyles}
`

export const Bezel = styled.svg`
  position: absolute;
  top: -0.9rem;
  width: 100%;
  height: 1rem;
  & path {
    fill: ${({ theme }) => theme.colors.pureWhite};
  }
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Subtitle = styled.span`
  ${({ theme }) => theme.textStyles.titleH6};
  color: ${({ theme }) => theme.colors.bismark};
  padding: 1.5rem 2rem 0;
  text-align: center;
  width: 100%;
`

export const Title = styled.h2<{
  $withNoMargintags?: boolean
}>`
  ${({ theme }) => theme.textStyles.titleH4};
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  padding: 1.5rem 2rem;
  &:last-child {
    padding-bottom: 2rem;
  }
  ${({ $withNoMargintags }) =>
    $withNoMargintags &&
    css`
      padding-top: 3rem;
      padding-bottom: 2rem;
    `}

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3};
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.bismark};
  padding: 0.5rem 2rem 2rem;
  text-align: center;
`

export const Button = styled.div`
  margin-top: 0.5rem;
  ${({ theme }) => theme.textStyles.titleH6};
  background-color: ${({ theme }) => theme.colors.creamCan};
  color: ${({ theme }) => theme.colors.arapawa};
  padding: 1.5rem 2rem;
  transition: color 0.3s, background-color 0.3s;
  width: 100%;
  text-align: center;
  a:hover & {
    background-color: ${({ theme }) => theme.colors.arapawa};
    color: ${({ theme }) => theme.colors.creamCan};
  }
  a:focus-visible & {
    background-color: ${({ theme }) => theme.colors.arapawa};
    color: ${({ theme }) => theme.colors.creamCan};
  }
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
`
