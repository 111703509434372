import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import tracking from 'src/tracking'

import {
  ImageHeaderBlock,
  PositiveFoodBlocksUnion,
  PositiveFoodContainerBlock as Data,
  PositiveFoodIntroBlock,
  PositiveFoodTabsBlock,
} from '../../../graphql/generated/api-graphql'
import { HeroVariant } from '../../../components/molecules/Hero/styled'
import { TagVariant } from '../../../components/atoms/Tag/styled'
import PositiveFoodContainer from '../../../components/molecules/PositiveFoodContainer'
import { transformWediaVideo, wediaImagesTransform } from '../../Common/wedia'
import { HeroProps } from '../../../components/molecules/Hero'
import { VideoBlockProps } from '../../../components/molecules/VideoBlock'
import { VideoPresentationProps } from '../../../components/molecules/VideoPresentation'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { PictureInformationProps } from '../../../components/molecules/PictureInformation'
import { ListIconHtmlVariant } from '../../../components/atoms/ListIconHtml/types'

import * as components from './Blocks/components'

function useBlockData<T extends PositiveFoodBlocksUnion>(
  blocks: PositiveFoodBlocksUnion[],
  name: string
): T | undefined {
  return blocks?.find((b) => b.__typename === name) as T
}

export type PositiveFoodContainerBlockProps = {
  data: Data
}

const PositiveFoodContainerBlock: FC<PositiveFoodContainerBlockProps> = ({
  data,
  ...otherParams
}) => {
  const { t } = useTranslation()
  const blocks = data?.blocks ?? []
  const router = useRouter()

  const imageHeaderBlock = useBlockData<ImageHeaderBlock>(
    blocks,
    'ImageHeaderBlock'
  )
  const positiveFoodTabsBlock = useBlockData<PositiveFoodTabsBlock>(
    blocks,
    'PositiveFoodTabsBlock'
  )
  const positiveFoodIntroBlock = useBlockData<PositiveFoodIntroBlock>(
    blocks,
    'PositiveFoodIntroBlock'
  )

  const otherBlocks = blocks?.filter?.(
    (b) =>
      ![
        'ImageHeaderBlock',
        'PositiveFoodTabsBlock',
        'PositiveFoodIntroBlock',
      ].includes(b?.__typename ?? '')
  )

  const heroPositiveProps: HeroProps = {
    variant: HeroVariant.positive,
    imageProps: {
      alt: t('positiveFood_title'),
      maxWidth: 1280,
      width: 1280,
      height: 512,
      withHD: true,
      withLazyLoading: false,
      images: wediaImagesTransform(imageHeaderBlock?.image),
    },
    mobileImageProps: {
      alt: t('positiveFood_title'),
      maxWidth: 370,
      width: 370,
      height: 200,
      withHD: true,
      withLazyLoading: false,
      images: wediaImagesTransform(imageHeaderBlock?.image),
    },
    banner: t('positiveFood_title'),
    brandLogoProps: {
      alt: t('positiveFood_title'),
      maxWidth: 120,
      width: 120,
      withHD: true,
      images: [
        {
          src: '/static/assets/images/common/positive-food-badge.svg',
          size: 120,
        },
      ],
    },
    topTags: positiveFoodTabsBlock?.tabs?.map((tab) => ({
      label: tab?.label ?? '',
      href: tab?.href?.replace(' ', '') ?? '',
      active: router.asPath === tab?.href?.replace(' ', ''),
      variant: TagVariant.positive,
      onClick: () => tracking.positive.content(tab?.label ?? ''),
    })),
    title: positiveFoodIntroBlock?.title ?? '',
    text: positiveFoodIntroBlock?.introduction ?? '',
    list: positiveFoodIntroBlock?.highlights
      ? {
          variant: ListIconHtmlVariant.Positive,
          items: positiveFoodIntroBlock?.highlights.map((item) => ({
            iconProps: {
              icon: Icons.like,
              width: 32,
              height: 32,
            },
            htmlProps: {
              text: item,
            },
          })),
        }
      : undefined,
  }

  const videoProps: VideoBlockProps | undefined =
    positiveFoodIntroBlock?.videoBlock &&
    (positiveFoodIntroBlock?.videoBlock?.videos?.length ?? 0) === 1
      ? {
          title: positiveFoodIntroBlock?.videoBlock?.title ?? '',
          text: positiveFoodIntroBlock?.videoBlock?.body ?? '',
          ...(positiveFoodIntroBlock?.videoBlock?.ctaBlock && {
            actionButtonProps: {
              ...ctaBlockToActionButtonProps(
                positiveFoodIntroBlock.videoBlock.ctaBlock
              ),
              variant: ActionButtonVariant.secondary,
              onClick: () =>
                tracking.cta(
                  positiveFoodIntroBlock?.videoBlock?.ctaBlock?.label ??
                    undefined,
                  positiveFoodIntroBlock?.videoBlock?.title ?? undefined
                ),
            },
          }),
          videoProps: transformWediaVideo(
            positiveFoodIntroBlock?.videoBlock?.videos?.[0]
          ),
        }
      : undefined

  const videoPresentationProps: VideoPresentationProps | undefined =
    positiveFoodIntroBlock?.videoBlock &&
    (positiveFoodIntroBlock?.videoBlock?.videos?.length ?? 0) > 1
      ? {
          title: positiveFoodIntroBlock?.videoBlock?.title ?? '',
          htmlProps: {
            text: positiveFoodIntroBlock?.videoBlock?.body ?? '',
          },
          ...(positiveFoodIntroBlock?.videoBlock?.ctaBlock && {
            actionButtonProps: {
              ...ctaBlockToActionButtonProps(
                positiveFoodIntroBlock.videoBlock.ctaBlock
              ),
              variant: ActionButtonVariant.secondary,
              onClick: () =>
                tracking.cta(
                  positiveFoodIntroBlock?.videoBlock?.ctaBlock?.label ??
                    undefined,
                  positiveFoodIntroBlock?.videoBlock?.title ?? undefined
                ),
            },
          }),
          videos:
            positiveFoodIntroBlock?.videoBlock?.videos?.map((video) =>
              transformWediaVideo(video)
            ) ?? [],
        }
      : undefined

  const pictureInformationProps: PictureInformationProps[] | undefined =
    positiveFoodIntroBlock?.cards && positiveFoodIntroBlock?.cards.length > 0
      ? positiveFoodIntroBlock?.cards.map((card) => ({
          title: card?.title ?? undefined,
          text: card.body ?? undefined,
          imageProps: card?.image
            ? {
                alt: ' ',
                maxWidth: 1280,
                withHD: true,
                withLazyLoading: true,
                images: wediaImagesTransform(card.image),
              }
            : undefined,
          actionButtonProps: card?.ctaBlock?.href
            ? {
                ...ctaBlockToActionButtonProps(
                  card.ctaBlock,
                  t('positive_food_learn_more')
                ),
                variant: ActionButtonVariant.secondary,
                iconProps: {
                  icon: Icons.arrowRight,
                },
                iconPosition: 'right',
                onClick: () =>
                  tracking.cta(
                    card.ctaBlock?.label ?? undefined,
                    card?.title ?? undefined
                  ),
              }
            : undefined,
        }))
      : undefined

  const renderBlock: any = useCallback(
    (block: PositiveFoodBlocksUnion & { __typename: string }, key: number) => {
      const Block: FC<any> = (components as any)[block.__typename] ?? null

      if (!Block) {
        console.warn('Missing block : ' + block.__typename)
        return null
      }

      return (
        <Block
          key={`${block.__typename}-${key}`}
          data={block}
          {...otherParams}
        />
      )
    },
    [otherParams]
  )

  return (
    <PositiveFoodContainer
      heroPositiveProps={heroPositiveProps}
      videoProps={videoProps}
      videoPresentationProps={videoPresentationProps}
      pictureInformationProps={pictureInformationProps}
    >
      {otherBlocks?.map(renderBlock)}
    </PositiveFoodContainer>
  )
}

export default PositiveFoodContainerBlock
