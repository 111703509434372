import tracking from 'src/tracking'

import { HeroProps } from '../../../components/molecules/Hero'
import { wediaImagesTransform } from '../../Common/wedia'
import { HeroBannerBlock } from '../../../graphql/generated/api-graphql'
import Router, { routes } from '../../../routes/Router'
import { TagVariant } from '../../../components/atoms/Tag/styled'

const heroBannerBlockTransform = (t: any, data: HeroBannerBlock): HeroProps => {
  return {
    title: data?.title ?? '',
    imageProps: {
      maxWidth: 1280,
      width: 1280,
      height: 512,
      withHD: true,
      withLazyLoading: false,
      images: data?.image ? wediaImagesTransform(data.image) : undefined,
      alt: data?.title ?? data.image?.alt ?? '',
    },
    mobileImageProps: {
      maxWidth: 370,
      width: 370,
      height: 200,
      withHD: true,
      withLazyLoading: false,
      images: data?.image ? wediaImagesTransform(data.image) : undefined,
      alt: data?.title ?? data.image?.alt ?? '',
    },
    ...(data?.type === 'recipe' && {
      linkProps: {
        href: Router.getRouteUrl(routes.recipe, { slug: data?.slug }),
        onClick: () => tracking.cards.hero(data?.title ?? ''),
      },
      buttonLabel: data?.subTitle ?? '',
    }),
    ...(data?.type === 'culinary_article' && {
      linkProps: {
        href: Router.getRouteUrl(routes.culinaryArticle, { slug: data?.slug }),
        onClick: () => tracking.cards.hero(data?.title ?? ''),
      },
      buttonLabel: data?.subTitle ?? '',
    }),
    ...(data?.type === 'article' && {
      linkProps: {
        href: Router.getRouteUrl(routes.healthArticle, { slug: data?.slug }),
        onClick: () => tracking.cards.hero(data?.title ?? ''),
      },
      buttonLabel: data?.subTitle ?? '',
    }),
    brandLogoProps: data.brand?.pictureLogo
      ? {
          maxWidth: 75,
          width: 80,
          height: 80,
          withHD: true,
          withLazyLoading: false,
          alt: data.brand.pictureLogo.alt ?? '',
          images: data.brand.pictureLogo
            ? wediaImagesTransform(data.brand.pictureLogo)
            : undefined,
        }
      : undefined,
    ...(data?.type === 'article' || data?.type === 'culinary_article'
      ? {
          topTags: [
            {
              label: t('tag_article'),
              value: t('tag_article'),
              variant: TagVariant.article,
            },
          ],
        }
      : data?.tags && {
          topTags: data?.tags.map((tag) => ({
            label: tag.name,
            value: tag.name,
            variant: TagVariant.article,
            linkProps: {
              href: Router.getRouteUrl(routes.tag, { slug: tag.slug }),
            },
          })),
        }),
  }
}

export default heroBannerBlockTransform
