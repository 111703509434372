import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PushHpRegisterBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import RegisterBlock, {
  RegisterBlockProps,
} from '../../../components/molecules/RegisterBlock'
import { RegisterBlockPosition } from '../../../components/molecules/RegisterBlock/styled'
import { selectors } from '../../../redux'
import { RegisterBlockDefaultProps } from '../../RegisterBlock/transform'

export type PushHpRegisterBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const PushHpRegisterBlock: FC<PushHpRegisterBlockProps> = ({ data }) => {
  const { t } = useTranslation()

  const isConnected = useSelector(selectors.auth.isConnected)

  const props: RegisterBlockProps = {
    ...RegisterBlockDefaultProps(t),
    title: data?.title ?? t('join_QVDF_community'),
    position: RegisterBlockPosition.row,
  }

  return !isConnected ? <RegisterBlock {...props} /> : null
}

export default PushHpRegisterBlock
