import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { GoodPlansListBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import GoodPlansList from '../../../components/molecules/GoodPlansList'
import { actions, selectors } from '../../../redux'
import PictureCardMessage from '../../../components/molecules/PictureCardMessage'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import Loader from '../../../components/atoms/Loader'

import {
  getCurrentPlans,
  getNoGoodPlansPictureCardProps,
  transformPlans,
} from './transform'

export type GoodPlansListBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const GoodPlansListBlock: FC<GoodPlansListBlockProps> = () => {
  const { t } = useTranslation()
  const isInit = useSelector(selectors.app.isInit)
  const [isFetched, setIsFetched] = useState(false)

  const [plans, loadPlans] = useServiceRequest(
    selectors.plans.plans,
    actions.plans.plansRequest,
    actions.plans.plansReset,
    () => setIsFetched(true)
  )

  const currentPlans = useMemo(
    () => getCurrentPlans(plans.data ?? []),
    [plans.data]
  )

  const cards = useMemo(
    () => transformPlans(currentPlans, t),
    [currentPlans, t]
  )

  useEffect(() => {
    loadPlans({})
  }, [loadPlans])

  if (!isInit || !isFetched) {
    return <Loader />
  }

  if (cards.length === 0) {
    return <PictureCardMessage {...getNoGoodPlansPictureCardProps(t)} />
  }

  return <GoodPlansList cards={cards} />
}

export default GoodPlansListBlock
