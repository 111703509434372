import tracking, { TrackingCardPosition } from 'src/tracking'
import { CardContentType } from 'src/components/molecules/ContentCard'

import { Recipe } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { toScoreLetter } from '../Common/recipe'
import { wediaImagesTransform } from '../Common/wedia'
import { transformTimeToMinutes } from '../../helpers/DateHelpers'

export const transformRecipeSmallCard = (
  data: Recipe,
  index: number,
  t: any,
  blockName?: string,
  showScore?: boolean
) => {
  const duration = data?.preparationTime
    ? transformTimeToMinutes(data.preparationTime)
    : 0

  return {
    type: CardContentType.Recipe,
    title: `${index + 1}. ${data?.title}`,
    ...(data?.slug && {
      linkProps: {
        href: Router.getRouteUrl(routes.recipe, { slug: data?.slug }),
        onClick: () =>
          tracking.cards.block(
            blockName,
            CardContentType.Recipe,
            data?.title,
            TrackingCardPosition.CONTENT
          ),
      },
    }),
    imageProps: {
      maxWidth: 300,
      width: 224,
      height: 140,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
      placeholder: !data?.thumbnail01 && !data?.mainImage,
      alt: data?.title ?? data?.thumbnail01?.alt ?? data?.mainImage?.alt ?? '',
    },
    duration:
      duration && duration > 0
        ? t('recipe_duration', { duration })
        : data?.tagsPreparationTime?.[0]?.name,
    brandName: data?.brand?.name,
    score: toScoreLetter(data?.nutriscoreLetter),
    alwaysShowScore: showScore,
  }
}
