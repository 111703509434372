import React, { FC } from 'react'

import FAQTheme, { FAQThemeProps } from '../FAQTheme'
import { FormFieldSelectProps } from '../../form/fields/FormFieldSelect'

import * as SC from './styled'

export type FAQProps = MinimalSeoBlock & {
  title?: string
  selectProps?: FormFieldSelectProps
  themes?: FAQThemeProps[]
  contact?: string
}

const ArticleBlock: FC<FAQProps> = ({
  htmlTag,
  title,
  selectProps,
  themes,
  contact,
}) => {
  return (
    <SC.FAQ as={htmlTag}>
      {title && <SC.Title>{title}</SC.Title>}
      {selectProps && <SC.Select {...selectProps} />}
      <SC.Themes>
        {themes?.map((theme, i) => (
          <FAQTheme {...theme} key={i} />
        ))}
      </SC.Themes>
      {contact && <SC.Contact>{contact}</SC.Contact>}
    </SC.FAQ>
  )
}

export default ArticleBlock
