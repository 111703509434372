import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import { ActionButtonVariant } from '../../atoms/ActionButton/styled'
import { LinkProps } from '../../atoms/Link'

import * as SC from './styled'

export type DiscoverCardProps = MinimalSeoBlock & {
  className?: string
  title: string
  linkProps: LinkProps
  backgroundColor?: string
  imageProps?: PictureProps
}

const DiscoverCard: FC<DiscoverCardProps> = (props) => {
  const {
    className,
    title,
    htmlTitleTag,
    linkProps,
    backgroundColor,
    imageProps,
  } = props

  return (
    <SC.DiscoverCard
      className={cx('DiscoverCard', className)}
      $backgroundColor={backgroundColor}
      $withBackground={imageProps ? true : false}
    >
      <SC.StyledLink
        $backgroundColor={backgroundColor}
        $withBackground={imageProps ? true : false}
        {...linkProps}
      >
        {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
        {linkProps.text && (
          <SC.Action
            variant={
              imageProps
                ? ActionButtonVariant.yellow
                : ActionButtonVariant.secondary
            }
            label={linkProps.text}
          />
        )}
      </SC.StyledLink>
      {imageProps && <SC.Background {...imageProps} aria-hidden />}
    </SC.DiscoverCard>
  )
}

export default DiscoverCard
