import React, { FC, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import tracking from 'src/tracking'
import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { ItemList } from 'schema-dts'

import {
  CheesePaginator,
  ListCheesesBlock as BlockData,
  WediaImage,
} from '../../../graphql/generated/api-graphql'
import AllCardsListing, {
  AllCardsListingProps,
} from '../../../components/molecules/AllCardsListing'
import CheeseCardBlock, {
  CheeseCardBlockProps,
} from '../../../components/molecules/CheeseCardBlock'
import { transformCheeseCard } from '../../CheeseCard/transformCheeseCard'
import Router, { routes } from '../../../routes/Router'
import { AllFiltersFormProps } from '../../../components/forms/AllCheesesFiltersForm'
import { formikFieldMocks } from '../../../components/form/FormikField/mocks'
import FormFieldSelect from '../../../components/form/fields/FormFieldSelect'
import { PaginationProps } from '../../../components/molecules/Pagination'
import {
  getFilterOptions,
  parseFilters,
  toFilterString,
} from '../../Common/filters'
import { jsonLdListCheeseBlock } from '../../Cheese/cheese'

export type PushHpBlockProps = {
  data: BlockData
  cheeses: CheesePaginator
  aopImage?: WediaImage
}
const ListCheesesBlock: FC<PushHpBlockProps> = ({
  data,
  cheeses,
  aopImage,
}) => {
  //const { t } = useTranslation()
  const { query } = useRouter()
  const { t } = useTranslation()
  const pageNumber: number = parseInt((query?.page as string) ?? '1', 10) ?? 1
  const ref = useRef<HTMLDivElement>(null)

  const recipeCardBlockProps: CheeseCardBlockProps = {
    cheeseCards: cheeses?.data?.map((item: any) => {
      return transformCheeseCard(t, item, aopImage, t('see_all_cheeses'))
    }),
  }

  const parsedFilters = useMemo(
    () =>
      parseFilters(
        t,
        'listCheese',
        query?.tag as string,
        Object.keys(data?.filters)
      ),
    [t, data?.filters, query?.tag]
  )

  const updateFilters = useCallback(
    (val: { [key: string]: string }) => {
      const tag = toFilterString(t, 'listCheese', { ...parsedFilters, ...val })
      if (tag) {
        Router.push(
          Router.getRouteUrl(routes.allCheesesTag, { tag }),
          undefined,
          {
            scroll: false,
          }
        )
        ref?.current?.scrollIntoView({
          behavior: 'smooth',
        })
      } else {
        Router.push(Router.getRouteUrl(routes.allCheeses), undefined, {
          scroll: false,
        })
      }
    },
    [t, parsedFilters]
  )

  // const handleFilterChange = (update: any, e: any) => {
  //   updateFilters(e)
  //   console.log(e)
  // }

  const handleFilterChange = useCallback(
    (update: any, name: any, e: any) => {
      updateFilters(update)
      if (name && e.target.value) {
        const item = data.filters[name].find(
          (el: any) => el.id.toString() === e.target.value
        )
        tracking.filter(t(`listCheese_filter_${name}_label`), item.name)
      }
    },
    [updateFilters, t, data?.filters]
  )

  const filters: AllFiltersFormProps = useMemo(
    () => ({
      formikForm: {
        initialValues: parsedFilters,
        onSubmit: () => undefined,
        validateOnChange: true,
        enableReinitialize: true,
      },
      fields: Object.keys(data?.filters)?.map((name) => ({
        select: {
          ...formikFieldMocks.basic,
          Component: FormFieldSelect,
          label: t(`listCheese_filter_${name}_label`),
          name,
          placeholder: t(`listCheese_filter_${name}_all`),
          staticLabel: true,
          options: getFilterOptions(t, 'listCheese', name, data?.filters),
          required: false,
          onChange: (e) =>
            handleFilterChange({ [e.target.name]: e.target.value }, name, e),
        },
      })),
    }),
    [parsedFilters, data?.filters, t, handleFilterChange]
  )

  const paginationProps: PaginationProps = {
    pageCount: cheeses?.paginatorInfo?.lastPage,
    pageRangeDisplayed: 2,
    marginPagesDisplayed: 2,
    forcePage: cheeses?.paginatorInfo?.currentPage - 1,

    hrefBuilder: (pageIndex) => {
      if (pageIndex === 1) {
        return Router.getRouteUrl(
          query?.tag ? routes.allCheesesTag : routes.allCheeses,
          { ...(query?.tag && { tag: query?.tag }) }
        )
      } else {
        return Router.getRouteUrl(
          query?.tag ? routes.allCheesesTagPage : routes.allCheesesPage,
          { page: pageIndex, ...(query?.tag && { tag: query?.tag }) }
        )
      }
    },

    onPageChange: (e) => {
      if (e.selected + 1 !== pageNumber) {
        Router.push(
          Router.getRouteUrl(
            query?.tag ? routes.allCheesesTagPage : routes.allCheesesPage,
            { page: e.selected + 1, ...(query?.tag && { tag: query?.tag }) }
          ),
          undefined,
          {
            scroll: false,
          }
        )
        ref?.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
  }

  const jsonLd = jsonLdListCheeseBlock(
    t('see_all_cheeses'),
    recipeCardBlockProps
  )

  const allCheesesProps: AllCardsListingProps = {
    title: t('see_all_cheeses'),
    paginationProps,
    allFiltersFormProps: filters,
    children: recipeCardBlockProps && (
      <>
        {jsonLd && (
          <Head>
            <script
              {...jsonLdScriptProps<ItemList>({
                '@context': 'https://schema.org',
                ...jsonLd,
              })}
            />
          </Head>
        )}
        <CheeseCardBlock {...recipeCardBlockProps} />
      </>
    ),
  }

  return <AllCardsListing {...allCheesesProps} ref={ref} />
}

export default ListCheesesBlock
