import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'

import {
  ArticleUnion,
  PositiveFoodDynamicArticlesBlock,
  PositiveFoodSelectedArticlesBlock as PositiveFoodSelectedArticlesBlockFromAPI,
} from '../../../../../graphql/generated/api-graphql'
import CardsBlock, {
  CardsBlockProps,
} from '../../../../../components/molecules/CardsBlock'
import Router from '../../../../../routes/Router'
import routes from '../../../../../routes/definitions'
import { TagVariant } from '../../../../../components/atoms/Tag/styled'
import { transformArticleCard } from '../../../../ArticleCard/transformArticleCard'
import { transformArticleBigCard } from '../../../../ArticleCard/transformArticleBigCard'
import { Icons } from '../../../../../components/atoms/Icon'

export type PositiveFoodSelectedArticlesBlockProps = {
  data:
    | PositiveFoodDynamicArticlesBlock
    | PositiveFoodSelectedArticlesBlockFromAPI
}
const PositiveFoodSelectedArticlesBlock: FC<
  PositiveFoodSelectedArticlesBlockProps
> = ({ data }) => {
  const { t } = useTranslation()

  const isSelectedBlock = 'withButton' in data

  const bigCard =
    data?.articles?.length &&
    (data?.articles.length == 1 || data?.articles.length >= 4)
      ? data?.articles[0]
      : undefined
  const smallCards =
    data?.articles?.length &&
    (data?.articles.length == 1 || data?.articles.length >= 4)
      ? data?.articles.slice(1)
      : data?.articles

  const actionButtonProps = {
    label: t('see_more_articles'),
    iconPosition: 'right',
    iconProps: {
      icon: Icons.arrowRight,
    },
    href: Router.getRouteUrl(routes.articles),
  }

  const positiveFoodSelectedArticlesBlockProps: CardsBlockProps | undefined =
    data
      ? {
          title: data.title ?? undefined,
          text: 'body' in data && data.body ? data.body : undefined,
          tags: data.tags
            ? data.tags.map((tag) => ({
                label: tag.name,
                value: tag.slug,
                href: Router.getRouteUrl(routes.tag, { slug: tag.slug }),
                onClick: () => tracking.tag(tag.name ?? '', data?.title ?? ''),
              }))
            : undefined,
          ...(isSelectedBlock
            ? data.withButton
              ? {
                  actionButtonProps:
                    actionButtonProps as CardsBlockProps['actionButtonProps'],
                }
              : undefined
            : {
                actionButtonProps:
                  actionButtonProps as CardsBlockProps['actionButtonProps'],
              }),
          highlighted: bigCard
            ? {
                ...transformArticleBigCard(
                  t,
                  bigCard,
                  data.title ?? undefined,
                  false
                ),
                brandLogoProps: {
                  alt: t('positive_food'),
                  maxWidth: 80,
                  width: 80,
                  withHD: true,
                  images: [
                    {
                      src: '/static/assets/images/common/positive-food-badge.svg',
                      size: 80,
                    },
                  ],
                },
                topTags: [
                  {
                    label: t('positive_food'),
                    variant: TagVariant.positive,
                  },
                ],
              }
            : undefined,
          cards: smallCards
            ? smallCards
                ?.map((item: ArticleUnion) =>
                  transformArticleCard(t, item, data.title ?? undefined, false)
                )
                ?.map((item) => ({
                  ...item,
                  tags: [
                    {
                      label: t('positive_food'),
                      variant: TagVariant.positive,
                    },
                    {
                      label: t('tag_article'),
                      variant: TagVariant.article,
                    },
                  ],
                }))
            : undefined,
        }
      : undefined

  return positiveFoodSelectedArticlesBlockProps ? (
    <CardsBlock {...positiveFoodSelectedArticlesBlockProps} />
  ) : null
}

export default PositiveFoodSelectedArticlesBlock
