import styled, { css } from 'styled-components'

import Icon from '../../atoms/Icon'
import Html from '../../atoms/Html'

export const FAQEntry = styled.div<{ $isOpen?: boolean }>`
${({ theme, $isOpen }) =>
  $isOpen &&
  css`
    ${Title} {
      color: ${theme.colors.pureWhite};
    }
    ${Head} {
      background-color: ${theme.colors.bismark};
    }
    ${Text} {
      display: block;
    }
    ${StyledIcon} {
      color: ${theme.colors.atomicTangerine};
    }
  `}}
`

export const Head = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2.4rem 1.6rem;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.snow};
`

export const Index = styled.span`
  ${({ theme }) => theme.textStyles.textLargeStrong};
  color: ${({ theme }) => theme.colors.greyChateau};
`
export const Title = styled.span`
  ${({ theme }) => theme.textStyles.textLargeStrong};
  color: ${({ theme }) => theme.colors.arapawa};
  flex-grow: 1;
  overflow-wrap: break-word;
`
export const StyledIcon = styled(Icon)`
  width: 1.6rem;
  height: 1.6rem;
  color: ${({ theme }) => theme.colors.ruby};
`
export const Text = styled(Html)`
  display: none;
  ${({ theme }) => theme.textStyles.textSmall};
  color: ${({ theme }) => theme.colors.bismark};
  padding: 1.6rem 1.6rem 2.4rem;
  & a {
    ${({ theme }) => theme.textStyles.textSmall};
    color: ${({ theme }) => theme.colors.bismark};
    text-decoration: underline;
  }
`
